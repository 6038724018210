.contact-page {
    .title {
        h2 {
            color: #fff;
        }
        p {
            color: #808080;
        }
    }

    .ant-input-affix-wrapper {
        background-color: #1a1a1a !important;
        border: none;
        border-radius: 20px;
    }

    input, textarea {
        padding: 8px 11px !important;
        background-color: #1a1a1a !important;
        color: #fff;
        border: none;
        border-radius: 20px;
    }

    input::placeholder, textarea::placeholder {
        color: #484848;
    }

    input:hover, .ant-input-affix-wrapper:hover {
        background-color: #1a1a1a !important;
    }

    .wrapper {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 30px;

        .left, .right {
            width: 50%;
        }

        .left {
            margin-right: 20px;
          .anticon{
            color: #fff;
            font-size: 12px;
          }
        }
    }

    #btn-send-contact {
        background: #feb034;
        height: 36px;
        border: none;
        font-weight: 500;
        margin-top: 30px;
        border-radius: 30px;
        color: #000;

    }

    #btn-send-contact:hover {
        color: #fff;
    }
}