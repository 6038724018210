.setting-wrapper-page {
    width: 80%;
    margin: 2rem auto 0;

    > .setting {
        width: 100%;
        margin: 2rem auto 0;
        background: #353333;
        border-radius: 10px;
        padding: 1rem 1rem 2rem 1rem;

        h3 {
            color: white;
        }

        > .item {
            display: flex;
            justify-content: space-between;
            height: 60px;
            border-bottom: 1px solid #565252;
            align-items: center;
            padding: 0 10px;

            .anticon {
                color: #fff;
                font-size: 12px;
                margin-right: 10px;
            }
            .anticon.anticon-right{
                font-size: 11px;
            }

            a, span {
                color: #fff;
                text-decoration: none;
            }
        }

        .item:last-child {
            border-bottom: none;
        }

        .item:hover {
            background-color: #000;
        }

        .item:hover a, .item:hover span {
            color: #feb034;
        }

        .ant-switch-checked {
            background-color: #00a652;
        }
    }

    .logout {
        text-align: center;
        margin: 30px 0;

        button {
            font-weight: 500;
            border-radius: 20px;
        }
    }

}
