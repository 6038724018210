.change-password-page {
    .wrapper {
        width: 50%;

        .title-change-password {
            h2 {
                color: #fff;
            }

            p {
                color: #808080;
            }
        }

        .site-form-item-icon, .ant-input-password-icon {
            color: #fff;
            font-size: 12px;
        }

        .ant-input-affix-wrapper {
            background-color: #1a1a1a;
            border: none;
            border-radius: 20px;
        }

        input {
            padding: 8px 11px !important;
            background-color: #1a1a1a;
            color: #fff;
        }

        input::placeholder {
            color: #484848;
        }

        input:hover, .ant-input-affix-wrapper:hover {
            background-color: #1a1a1a;
        }

        #btn_change_password {
            background: #feb034;
            height: 36px;
            border: none;
            font-weight: 500;
            margin-top: 30px;
            border-radius: 30px;
            color: #000;

        }

        #btn_change_password:hover {
            color: #fff;
        }
    }
    @media screen and (max-width: 991px) {
        .wrapper{
            width: 70%;
        }
    }
    @media screen and (max-width: 767px) {
        .wrapper{
            width: 80%;
        }
    }
    @media screen and (max-width: 576px) {
        .wrapper{
            width: 100%;
        }
    }

}