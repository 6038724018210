.update-profile-after-sign-up{
    width: 50%;
    margin: 0 auto;
    padding: 6rem 2rem;

    .form-update-profile{
        > .head-widget{
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;
            > button{
                background: none;
                color: #feb034;
                border: none;
                font-weight: 500;
                margin-bottom: 30px;
                height:0;
                padding: 0;
                > span{
                    text-decoration: underline;
                }
            }
            > h4{
                color : #fff
            }
        }
        #form_update_profile_sign_up{
            .ant-form-item{
                margin-bottom: 15px;
            }
            .ant-input-affix-wrapper{
                background: #1a1a1a;
                border: none;
                border-radius: 20px;
                .ant-input-prefix{
                    .anticon{
                        font-size: 11px;
                        color: #fff;
                    }
                }
            }
            input{
                height: 38px;
                color: #fff;
                background: #1a1a1a;
                padding: 0 10px;
            }
            input::placeholder{
                color: #808080;
            }

            .status-vaccine  {
                .ant-select {
                    .ant-select-selector {
                        background: #1a1a1a!important;
                        border: none;
                        border-radius: 20px;
                        height: 46px;
                        text-align: left;
                        margin-bottom: 10px;
                        > .ant-select-selection-placeholder{
                            line-height: 46px;
                            padding-left: 24px;
                            color: grey;
                        }
                        .ant-select-selection-item{
                            color: #fff;
                            padding: 0 24px;
                            line-height: 46px;
                        }
                    }
                    .ant-select-arrow{
                        right: 20px;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        color: grey;
                    }
                }

            }
            .icon-vaccine{
                position: absolute;
                top: 28%;
                transform: translate(-50%, -50%);
                left: 16px;
                span.anticon{
                    font-size: 11px;
                    color: #fff;
                }
            }


            #btn-update-profile{
                background: #feb034;
                color: #000;
                font-weight: 500;
                border: none;
                margin: 10px 0 30px 0;
                height: 42px;
                border-radius: 5px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .update-profile-after-sign-up{
        width: 80%;
    }
}
