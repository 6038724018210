.modal-upload-personal-license{
    > .ant-modal-content{
        background: #0c0c0c;
        > .ant-modal-body{
            > .head-modal{
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                margin-bottom: 30px;
                > h5 {
                    color: #fff;
                    text-transform: uppercase;
                }
                > button{
                    background: none;
                    border: none;
                    color: #feb034;
                    height: 0;
                    padding: 0;
                    font-size: 13px;
                    font-weight: 500;
                }
            }
            > .body-modal{
                > .widget{
                    border: 1px solid #1a1a1a;
                    padding: 20px;
                    margin-bottom: 10px;
                    .head-widget{
                        text-align: center;
                        p.title-widget{
                            font-size: 16px;
                            color: #fff;
                            font-weight: 500;
                        }
                    }
                    > .upload-license{
                        display: flex;
                        justify-content: space-between;
                        > div {
                            //display: flex;
                            //align-items: flex-end;
                            > p.title{
                                color: #fff;
                                font-size: 14px;
                            }
                            > .license-drive-upload{
                                text-align: center;
                                > .ant-upload{
                                    width: 80px;
                                    height: 80px;
                                    background: #feb034;
                                    border: none;
                                }
                            }
                        }
                    }
                    > .upload-w9-w8ben{
                        display: block;
                        > .type-upload{
                            text-align: center;
                            margin-bottom: 30px;
                            > .ant-radio-group{
                                > .ant-radio-button-wrapper{
                                    border: none;
                                    margin-right: 5px;
                                    background: #808080;
                                    color: #000;
                                    font-weight: 500;
                                    &:before{
                                        background: none;
                                    }
                                }
                                .ant-radio-button-wrapper-checked{
                                    background: #feb034;
                                    &:before{
                                        background: none;
                                    }
                                }
                            }
                        }
                        > .w-9-w-8ben{
                            //display: flex;
                        }
                    }
                }
            }
            > .footer-modal{
                margin-top: 30px;
                text-align: center;
                > button{
                    color: #000;
                    font-weight: 500;
                    border: none;
                    height: 38px;
                }
                > button{
                    background: #4cd137;
                }
                > button.skip{
                    margin-right: 10px;
                    background: #feb034;
                }

            }
        }
    }
}