.update-profile-page {
    > .form-update-profile{
        padding: 4rem;
        h2, h5 {
            color: white;
        }
        .wrapper-inner {
            display: flex;
            justify-content: space-between;

            .left {
                width: 55%;
                margin-right: 30px;

                .image-profile {
                    margin-bottom: 20px;
                    > p {
                        color: #fff;
                    }

                    .avatar-uploader {
                        .ant-upload {
                            border-radius: 50%;
                            border: none;
                            position: relative;
                        }

                        .ant-upload.ant-upload-select-picture-card {
                            width: auto;
                            height: auto;
                        }
                    }

                    .icon-upload {
                        position: absolute;
                        font-size: 16px;
                        background: #feaf34;
                        right: 5px;
                        padding: 5px;
                        color: black;
                        border-radius: 50%;
                        top: 0;
                    }

                }
                .name{
                    display: flex;
                    > div:first-child{
                        margin-right: 10px;
                    }
                }
            }

            .right {
                width: 45%;
                textarea {
                    background: #1a1a1a;
                    border: none;
                    color: #fff;
                    padding: 10px 30px;
                    border-radius: 20px;
                }
                textarea::placeholder{
                    color: #808080;
                }
            }
            .status-vaccine  {
                .ant-select {
                    .ant-select-selector {
                        background: #1a1a1a!important;
                        border: none;
                        border-radius: 20px;
                        height: 49px;
                        text-align: left;
                        margin-bottom: 10px;
                        > .ant-select-selection-placeholder{
                            line-height: 49px;
                            padding-left: 24px;
                            color: grey;
                        }
                        .ant-select-selection-item{
                            color: #fff;
                            padding: 0 24px;
                            line-height: 49px;
                        }
                    }
                    .ant-select-arrow{
                        right: 20px;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        color: grey;
                    }
                }

            }
            .icon-vaccine{
                position: absolute;
                top: 25%;
                transform: translate(-50%, -50%);
                left: 16px;
                span.anticon{
                    font-size: 11px;
                    color: #fff;
                }
            }

        }
        .h-150 {
            height: 150px;
        }


        .icon-user {
            background: none;
            font-size: 14px;
            margin-right: 5px;
        }

        .ant-input-affix-wrapper {
            background: #1a1a1a;
            color: #ffffff;
            border: none;
            border-radius: 20px;
            line-height: 3;
            > .ant-input-prefix{
                > .anticon{
                    font-size: 12px;
                }
            }
        }

        input {
            background: #1a1a1a;
            color: #fff;
            padding-left: 10px !important;
        }

        input::placeholder {
            color: #484848;
        }

        input:hover , .ant-input-affix-wrapper:hover {
            background-color: #1a1a1a;
        }

        p {
            color: #808080;
            margin-bottom: 2rem;
            font-size: 1rem;
        }

        #btn-update-profile {
            background: #feaf34;
            border-radius: 30px;
            height: 50px;
            border: none;
            margin-bottom: 1rem;
            font-weight: 500;

            span {
                background: none;
                font-size: 20px;
                color: black;
            }
        }
    }

}
