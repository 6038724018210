.signup-wrapper {
    padding: 3rem;

    .title-signup {
        height: 100px;

        > p {
            font-size: 16px;
        }
    }

    input {
        background: #1a1a1a;
        color: #fff;
        padding-left: 10px !important;
        font-size: 16px;
    }

    input::placeholder {
        color: #484848;
        font-weight: 500;
    }

    input:hover {
        background-color: #1a1a1a;
    }

    .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        background-color: #1a1a1a;
    }

    .ant-input-affix-wrapper {
        line-height: 2;
    }

    span {
        border-radius: 30px;
        background: #1a1a1a;
        color: #ffffff;
        font-size: 16px;
        border: none;
    }

    span.ant-checkbox {
        //border: 1px solid #d9d9d9;
    }

    span.ant-checkbox-checked {
        border: none;
    }

    span.anticon {
        font-size: 14px;
    }

    span.ant-checkbox-inner {
        background: #000;
        border: 1px solid #6b6b6b;
    }

    h2, h4 {
        color: white;
    }

    h2 {
        margin-bottom: 1rem;
    }

    p {
        color: #7f7f7f;
    }

    .ant-input-prefix {
        background: none;
    }

    .head-signup {
        display: flex;
        justify-content: space-between;
        padding: 0 2rem;
    }

    .wp-inner-sign-up {
        display: flex;
        justify-content: space-between;
        padding: 0 2rem;

        > .left {
            width: 40%;

            input {
                padding: 8px;
            }

            .ant-input-password-icon {
                font-size: 12px;
                font-weight: 500;
            }

            .ant-input-password-icon:hover {
                color: #fff;
            }
        }

        > .right {
            width: 40%;

            .title-signup-other {
                font-size: 16px;
            }

            .ant-checkbox + span {
                background: none;
            }

            .ant-checkbox-group-item:first-child {
                margin-right: 50px;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #feaf34;
                border-color: #feaf34;
            }

            #btn-signup {
                border-radius: 30px;
                height: 50px;
                border: none;
                margin-bottom: 1rem;
                background: #feaf34;
                font-weight: 500;

                span {
                    background: none;
                    font-size: 20px;
                    color: #000;
                }
            }
        }

        .redirect-login {
            p {
                font-size: 16px;

                > a {
                    color: #1890ff;
                }
            }
        }

        .agree-term-policy {
            color: #fff;
            font-size: 16px;

            > span {
                background: none;
                text-decoration: underline;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .wp-inner-sign-up {
            > .left {
                width: 45%;
            }

            > .right {
                width: 45%;

                .ant-checkbox-group-item:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .wp-inner-sign-up {
            display: block;

            > .left {
                width: 100%;
                margin-bottom: 22px;
            }

            > .right {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .signup-wrapper {
        padding: 0;

        > .head-signup {
            display: block;

            > .title-signup {
                height: auto;

                > div {
                    justify-content: flex-start !important;
                }
            }
        }

        .redirect-login {
            padding-bottom: 20px;
        }
    }
    .page-children {
        width: 100%;
        margin: 0;
    }
}
