.download-app-page{
    padding: 6rem 3rem 10rem 3rem;
    background: #0c0c0c;
    .download-app{
        .card-app{
            min-height: 250px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            text-align: center;
            position: relative;
            background: #dcdde1;
            border-radius: 5px;
            box-sizing: border-box;
            margin-bottom: 10px;
            > div {
                width: 100%;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                > .anticon{
                    font-size: 35px;
                }
                .anticon-android{
                    color: #44bd32;
                }
                .anticon-apple{
                    color: #e67e22
                }
                > p{
                    font-weight: 600;
                }
                > a{
                    display: block;
                    padding-top: 20px;
                    font-size: 14px;
                    font-weight: 500;
                }
                > a:hover{
                    color: #000;
                }
            }
        }
        .redirect-use-web{
            padding-top: 50px;
            text-align: center;
            > a{
                font-size: 14px;
                background: #f1c40f;
                padding: 8px 10px ;
                color: #000;
                border-radius: 5px;
                font-weight: 600;
                text-align: center;
            }
            > a:hover{
                color: #0984e3;
            }
        }
    }
}