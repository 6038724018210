.booking-page {
  //height: auto;
  padding: 70px 0;

  .map-appointment {
    .input-search-gg {
      background: #1a1a1a;
      border: none;
      padding: 12px 10px;
      margin-bottom: 20px;

      > .ant-input-prefix {
        margin-right: 10px;

        .anticon {
          color: #fff;
          font-size: 12px;
        }
      }

      > input::placeholder {
        color: #808080;
      }
    }
  }

  .full-crews {
    text-align: center;
    margin-top: 20px;

    button {
      margin: 0 auto;
      background: #feb034;
      color: #000;
      font-weight: 500;
      border: none;
    }
  }

  .wp-map {
    padding: 50px 0 10px;
  }

  .wp-equipment {
    //height: auto;
    background-color: #0c0c0c;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
    padding-bottom: 50px;

    .option-select-date-appointment {
      margin-bottom: 15px;
    }

    .title {
      color: #fff;

      span {
        font-size: 14px;
        padding-left: 5px;
      }
    }

    .description {
      p {
        color: #808080;
        margin-bottom: 5px;
      }
    }

    .ant-input-affix-wrapper, .ant-input-number-input-wrap {
      background: #1a1a1a;
      color: #ffffff;
      font-size: 15px;
      border: none;
      border-radius: 15px;
    }

    input {
      background: #1a1a1a;
      color: #fff;
      padding-left: 10px !important;
    }

    input::placeholder, textarea::placeholder {
      color: #484848;
    }

    input:hover {
      background-color: #1a1a1a;
    }

    .ant-input-affix-wrapper {
      padding: 8px 0;
    }

    .list-services {
      .ant-select-selector {
        height: 60px;
      }

      .ant-select-selection-item {
        img {
          margin-right: 10px;
        }
      }

      .ant-select-item-option {
        padding: 8px 12px !important;

        p {
          margin: 0;
          padding: 10px 0;
        }

        img {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .date-time {
      .title {
        margin: 0;
      }

      .wp-inner {
        display: flex;
        justify-content: space-between;

        div {
          width: 100%;
          margin-right: 5px;
          border-radius: 15px;
          padding: 5px 0;
        }

        .ant-picker {
          background: #1a1a1a !important;
          border: none;

          input::placeholder {
            color: #484848;
          }

          .anticon {
            color: #fff;
            margin-right: 10px;
          }

          .ant-picker-input {
            .ant-picker-suffix {
              position: absolute;
              right: 10px;
            }
          }
        }

      }
    }

    .upload {
      min-height: 150px;
      padding-bottom: 30px;
    }

    .services {
      .list-services-config {
        .slick-slide {
          cursor: pointer;
          padding: 0 20px;
          box-sizing: border-box;

          .item {
            text-align: center;
            border: 1px solid #808080;
            padding: 20px 10px 0 10px;
            min-height: 160px;
            position: relative;
            border-radius: 5px;

            .thumb-item {
              margin-bottom: 10px;

              img {
                display: inline-block;
              }
            }

            .info-item {
              p {
                color: #fff;
              }
            }

          }

          .item.selected {
            border: 1px solid #feb034;
            position: relative;

            > .anticon {
              position: absolute;
              color: #feb034;
              top: 0;
              right: -5px;
            }

          }
        }

        .slick-slide:nth-child(4n) {
          margin-right: 0;
        }

        .slick-dots {
          li {
            button::before {
              color: #808080;
            }
          }
        }
      }
    }

    .list-crews {
      .empty-data {
        .ant-empty-normal {
          margin: 10px;

          .ant-empty-image {
            .ant-empty-img-simple {
              width: 30px;
              height: 25px;
            }
          }

          .ant-empty-description {
            color: #808080;
          }
        }
      }

      .show-loading {
        text-align: center;
        min-height: 100px;

        > .ant-spin {
          line-height: 100px;
        }
      }

      .list {
        padding: 0 20px;

        .wp-info-crew {
          display: block;
          padding: 10px 0;
          cursor: pointer;

          .info-crew {
            border: 1px solid #808080;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-radius: 5px;

            .info {
              display: flex;
              width: 100%;

              .thumb {
                text-align: center;
                margin-right: 20px;
                padding-top: 10px;

                p {
                  color: #fff;
                  margin-top: 10px;
                  margin-bottom: 0;
                  font-weight: 500;
                }

                span {
                  color: #fff;
                }

                img {
                  display: inline-block;
                }
              }

              .detail {
                width: 100%;

                p {
                  color: #808080;
                }

                p.name {
                  font-size: 18px;
                  font-weight: 500;
                  color: #fff;
                }

                p:not(p.name) {
                  padding-left: 10px;
                  position: relative;

                  > .anticon {
                    color: #feb034;
                    font-size: 11px;
                    position: absolute;
                    top: 45%;
                    left: 0;
                    transform: translate(-50%, -50%);
                  }
                }

                .name-rate {
                  display: flex;
                  justify-content: space-between;

                  .rate {
                    .ant-rate {
                      font-size: 0;

                      .ant-rate-star {
                        font-size: 13px;
                      }
                    }

                  }
                }
              }
            }
          }

          .info-crew.selected {
            border: 1px solid #feb034;
            position: relative;

            > .anticon {
              position: absolute;
              top: -5px;
              right: -5px;
              color: #feb034;
            }
          }
        }
      }
    }

    .equipment {
      .note-select-option {
        color: #fff;
      }
    }

    .note, .budget {
      input {
        padding: 8px 10px;
      }

      .ant-select-selector {
        height: 40px;

        .ant-select-selection-placeholder {
          line-height: 40px;
        }

        .ant-select-selection-item {
          line-height: 40px;
        }
      }
    }
  }

  .wp-payment {
    .head-payment {
      text-align: right;

      button.back {
        background: none;
        color: #feb034;
        font-weight: 600;
        border: none;
        position: relative;
        padding: 0;

        .anticon {
          font-size: 11px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(-50%, -50%);
        }
      }
    }

    .payment {
      height: auto;
      background-color: #0c0c0c;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
      padding: 50px;

      .ant-radio-group {
        display: flex;
        justify-content: center;

        .ant-radio-button-wrapper::before {
          background: none;
        }

        .ant-radio-button-wrapper {
          background: none;
          height: auto;
          border: none;
          width: 250px;
          box-sizing: border-box;
          margin-right: 30px;
          padding: 0;

          .list-cards {
            width: 100%;
            //height: 120px;
            margin-right: 40px;
            background: #1a1a1a;
            border: none;
            text-align: center;
            position: relative;

            > div {
              display: flex;
              justify-content: flex-start;
              position: relative;

              .card-icon {
                font-size: 18px;
                color: #808080;
                position: absolute;
                top: 50%;
                left: 20px;
                transform: translate(-50%, -50%);
              }

              p {
                color: #808080;
                padding-top: 10px;
                padding-left: 50px;
              }

              .anticon-check-circle {
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translate(-50%, -50%);
                font-size: 13px;
                color: #feb034;
              }
            }
          }
        }

        .ant-radio-button-wrapper:last-child {
          margin-right: 0;
        }

        .ant-radio-button-wrapper-checked {
          border: 1px solid #feb034;

          .ant-radio-button-checked {
            border: 1px solid #feb034;
          }

          > span > .card {
            .card-icon, p {
              color: #fff;
            }
          }

        }

        .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
          border-right: 1px solid #feb034;
        }
      }

      .payment-method {
        .add-more-payment {
          text-align: center;
          padding: 20px 0;

          button {
            color: #feb034;
            font-size: 16px;
            font-weight: 500;
            background: none;
            border: none;
            outline: none;
            position: relative;

            .anticon {
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              left: 0;
            }
          }

        }
      }

      .details {
        .amount {
          font-size: 30px;
          color: #feb034;
          text-align: center;
          font-weight: 500;
        }

        .detail-service {
          //background: none;
          border: none;
          margin: 0 auto;
          color: #fff;

          .ant-menu-submenu-arrow {
            color: #fff;
            left: 80px;
          }
        }

        .site-collapse-custom-panel.detail-service {
          margin-bottom: 24px;
          overflow: hidden;
          background: #f7f7f7;
          border: 0;
          border-radius: 2px;
        }
      }
    }

    .title {
      color: #fff;
      font-size: 18px;

      span {
        font-size: 14px;
        padding-left: 5px;
      }
    }

    .ant-collapse.detail-service {
      width: 400px;
      margin: 0 auto;
      background: none;
    }

    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
      background: #1a1a1a;
      padding-top: 20px;
    }

    .ant-collapse-item {
      border-bottom: none !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      text-align: center;
      color: #fff;
    }

    .ant-collapse-content-active {
      width: 400px;
      background: #1a1a1a;
      margin: 0 auto;
    }

    .ant-collapse-content-box {
      > div {
        display: flex;
        justify-content: space-between;

        p {
          color: #fff;
          font-size: 14px;

        }

        > p.title {
          color: #808080;
        }

      }
    }

    #btn-payment-now {
      border-radius: 20px;
      background: #feb034;
      color: #000;
      font-weight: 500;
      border: none;
      outline: none;
      margin: 30px 0 100px 0;
    }
  }

  .book-full-crews {
    .head-booking {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      p.title {
        color: #fff;
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;
      }

      > button {
        position: relative;
        background: none;
        border: none;
        font-weight: 600;
        color: #feb034;

        .anticon {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 11px;
        }
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #1a1a1a !important;
    border: none !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #fff;

    .ant-select-selection-placeholder {
      color: #808080;
    }
  }

  .ant-upload-list-item {
    .ant-upload-list-item-info {
      .ant-upload-list-item-name {
        color: #808080;
      }

      .ant-upload-list-item-card-actions {
        button {
          > .anticon {
            color: #808080;
          }
        }
      }
    }
  }

  .ant-radio-wrapper {
    > span {
      color: #808080;
    }
  }

}

.ant-input {
  background-color: #1a1a1a !important;
  color: #fff !important;
  border: none !important;
}

.item-service.ant-select-item-option, .ant-select-selection-item {
  padding: 10px 12px;

  img {
    display: inline-block;
    margin-right: 10px;
  }

  .wp-item {
    display: flex;
    align-items: center;

    .thumb-item {
      margin-right: 10px;
    }

    .info-item {
      p {
        text-align: center;
        margin: 0;

        span {
          font-size: 12px;
        }
      }
    }
  }
}

.ant-select-selection-item {
  .wp-item {
    .info-item {
      p {
        font-weight: 500;
        margin: 0;
      }
    }
  }
}

.ant-radio-wrapper {
  margin-right: 100px !important;

  div {
    p {
      color: #fff;
      margin: 0;
    }

    p:last-child {
      color: #808080;
      font-size: 13px;
    }
  }
}

.anticon-dollar-circle {
  margin-left: 10px;
}

.ant-picker-clear:hover, .anticon-close-circle:hover, .ant-picker-input:hover .ant-picker-clear {
  background: none !important;
}


#appointment_note {
  background: rgb(26, 26, 26);
  border: none;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
}

.btn-upload-file {
  outline: none !important;
  background: none !important;
  border: none !important;

  span {
    color: #feb034;
  }
}

#btn-confirm-appointment {
  border-radius: 20px;
  background: #feb034;
  color: #000;
  font-weight: 500;
  border: none;
  outline: none;
  margin: 30px 0 100px 0;
}

.modal-booking-success {
  > .ant-modal-content {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    background: #0c0c0c;

    .ant-modal-body {
      text-align: center;
      border-radius: 10px;

      p {
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        padding: 10px 0;
      }

      button {
        background: #feb034;
        border: none;
        color: #000;
        font-weight: 500;
      }

      .anticon {
        font-size: 20px;
        background: #34fe51;
        padding: 10px;
        border-radius: 50%;
        color: #fff;
        margin: 20px 0;
      }
    }
  }
}

.modal-confirm-pay-appointment {
  .ant-modal-content {
    border-radius: 10px;
    background: #1a1a1a;

    .ant-modal-body {
      border-radius: 10px;
      background: #1a1a1a;

      .body-modal {

        .title-modal {
          text-align: center;

          p {
            font-size: 18px;
            color: #fff;
            font-weight: 500;
          }
        }

        .content-pay {
          > div {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            .title, .value {
              p {
                font-size: 16px;
                color: #fff;
              }
            }
          }
        }

        .footer-modal {
          text-align: center;

          > button {
            border: none;
            font-weight: 500;
            margin-right: 10px;
          }

          button.cancel-btn {
            background: #ca3e47;
          }

          button.pay-btn {
            background: #f1c40f;
          }

          button:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

.ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
}
