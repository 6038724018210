.modal-show-list-connections{
    > .ant-modal-content{
        background: #0c0c0c;
        > .ant-modal-body{
            background: #0c0c0c;
            height: 90vh;
            overflow: auto;
            padding: 20px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            > .head-modal{
                display: flex;
                justify-content: space-between;
                text-align: right;
                margin-bottom: 15px;
                > button {
                    background: none;
                    border: none;
                    color: #feb034;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 0;
                    height: 0;
                }
                h5.title-modal{
                    color: #fff;
                }
            }
            > .body-modal  {
                > .form-search{
                    position: relative;
                    input{
                        padding: 8px 10px;
                    }
                    input::placeholder{
                        color: #808080;
                    }
                    .anticon.icon-search{
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        color: #808080;
                        font-size: 13px;
                        transform: translate(-50%, -50%);
                    }
                }
                > .content{
                    > .list {
                        margin-top: 30px;
                        > .item{
                            background: #0c0c0c;
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            padding: 20px;
                            align-items: center;
                            margin-bottom: 15px;
                            box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
                            border-radius: 5px;
                            > .left{
                                display: flex;
                                > .thumb{
                                    margin-right: 15px;
                                    padding-top: 5px;
                                    cursor: pointer;
                                }
                                > .name{
                                    > p {
                                        margin-bottom: 0;
                                        color: #fff;
                                        font-size: 16px;
                                        cursor: pointer;
                                    }
                                    > p.address{
                                        position: relative;
                                        padding-left: 15px;
                                        color: #808080;
                                        font-size: 13px;
                                        .anticon{
                                            font-size:10px ;
                                            position: absolute;
                                            top: 50%;
                                            transform: translate(-50%, -50%);
                                            left: 5px;
                                            color: #feb034;
                                        }
                                    }
                                }
                            }
                            > .right{
                                > button{
                                    background: #feb034;
                                    color: #000;
                                    font-weight: 500;
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .widget-empty-data{
            background: #0c0c0c;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 100px 0;
        }
    }
}