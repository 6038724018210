.appointment-crews-page {
    > .appointment {
        .ant-tabs {
            .ant-tabs-nav::before {
                border-bottom: none;
            }

            .ant-tabs-tab + .ant-tabs-tab {
                margin: 0;
            }

            .ant-tabs-nav {
                .ant-tabs-ink-bar-animated {
                    max-width: 0;
                }

                .ant-tabs-nav-wrap {
                    width: 100%;
                    background: #0c0c0c;
                    border-radius: 30px;

                    .ant-tabs-nav-list {
                        width: 100%;

                        .ant-tabs-tab {
                            width: 50%;
                            text-align: center;
                            border-radius: 30px;

                            .ant-tabs-tab-btn {
                                width: 100%;
                                color: #808080;
                                font-weight: 500;

                            }
                        }

                        .ant-tabs-tab-active {
                            background: #feb034;

                            .ant-tabs-tab-btn {
                                color: #000;
                            }
                        }
                    }
                }
            }

            .ant-tabs-tabpane {
                .item {
                    background: #0c0c0c;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    padding: 20px;
                    align-items: center;
                    margin-bottom: 15px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
                    border-radius: 5px;

                    .info-crew {
                        width: 100%;
                        .info-top {
                            //margin-bottom: 20px;
                            display: flex;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: space-between;

                            .amount {
                                margin-right: 20px;

                                p {
                                    background: #00a652;
                                    color: #fff;
                                    border-radius: 30px;
                                    padding: 7px 20px;
                                    text-align: center;
                                }
                            }

                            .info {
                                p.name {
                                    color: #fff;
                                    font-weight: 500;
                                    margin: 0;
                                    padding-bottom: 5px;
                                    font-size: 18px;
                                }
                            }
                        }

                        .info-bottom {
                            display: flex;
                            flex-wrap: nowrap;
                            .time-address{
                                min-width: 300px;
                                max-width: 300px;
                                padding-right: 30px;
                                p{
                                    color: #fff;
                                    position: relative;
                                    padding-left: 15px;
                                    .anticon{
                                        position: absolute;
                                        top : 50%;
                                        left: 0;
                                        color: #feb034;
                                        font-size: 13px;
                                        transform: translate(-50%, -50%);
                                    }
                                }
                            }
                            .info{
                                > div{
                                    display: flex;
                                    margin-bottom: 5px;
                                }
                                div.title{
                                    min-width: 120px;
                                    p{
                                        color: #fff;
                                    }
                                }
                                div.detail{
                                    .ant-avatar{
                                        margin-right: 20px;
                                        cursor: pointer;
                                    }
                                    span.name, span.feedback{
                                        color: #fff;
                                    }
                                    span.name{
                                        font-size: 16px;
                                        cursor: pointer;
                                    }
                                    span.feedback{
                                        font-size: 13px;
                                        display: block;
                                        color: #808080;
                                    }
                                    .ant-rate-star{
                                        font-size: 13px;
                                    }
                                }
                                div.check-pay-appointment{
                                   > p{
                                       padding-top: 10px;
                                       color: #e74c3c;
                                       cursor: pointer;
                                       margin-bottom: 0;
                                   }
                                    > p:hover{
                                        text-decoration: underline;
                                    }
                                }
                                > div.btn-detail{
                                    display: block;
                                    text-align: left;
                                    margin-top: 10px;
                                    button{
                                        display: block;
                                        border-radius: 5px;
                                        border: none;
                                        color: #fff;
                                        text-align: center;
                                        font-weight: 500;
                                        background: #6b48ff;
                                    }
                                }
                            }
                        }
                    }
                }

                @media (max-width: 720px) {
                    .body-list-history{
                        >  .item {
                            display: block;
                            .info-crew{
                                > .info-bottom{
                                    display: block;
                                    > .time-address{
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                @media (max-width: 420px) {
                    .body-list-history{
                        >  .item {
                            display: block;
                            .info-crew{
                                > .info-top{
                                    display: block;
                                }
                            }

                        }
                    }
                }
            }
        }
        .widget-empty-data{
            background: #0c0c0c;
            min-height: 250px;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 100px 0;
        }
    }
}
.wp-loading-data{
    text-align: center;
    background: #0c0c0c;
    .spin-loading-data{
        padding: 50px 0;
        .ant-spin-dot{
            font-size: 16px;
            .ant-spin-dot-item{
                background-color: #fff;
                width: 7px;
                height: 7px;
            }
        }
        .ant-spin-text{
            font-size: 13px;
            color: #fff;

        }
    }
}