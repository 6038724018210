.modal-check-appointment{
    .ant-modal-content{
        background: #0c0c0c;
        .ant-modal-body{
            background: #0c0c0c;
            .head-modal{
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                > h5{
                    color: #fff;
                }
                > button{
                    background: none;
                    color: #feb034;
                    border: none;
                    height: 0;
                    padding: 0;
                    font-weight: 500;
                }
            }
            .body-modal {
                .content-check{
                    text-align: center;
                    p {
                        color: #fff;
                    }
                }
                .btn-pay{
                    button{
                        color: #000;
                        background: #4cd137;
                        border: none;
                        font-weight: 500;
                    }
                    button:hover{
                        color: #fff;
                    }
                }
            }
        }
    }
}

