.header {
  //position: fixed;
  top: 0; /* Stick it to the top */
  max-height: 70px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-template-areas: "logo nav";
  /* Cosmetics */
  //background-color: #282c34;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #353333;

  span {
    border-radius: 30px;
    background: #353333;
    color: #dd9930;
    font-size: 15px;
    border: none;
  }

  .ant-input-affix-wrapper:hover {
    background-color: #353333;
  }

  input {
    background: #353333;
    color: #fff;
  }

  input:hover {
    background-color: #353333;
  }
}


.header > div {
  /*
    1/3  - 3 columns per row
    10px - spacing between columns
  */
  box-sizing: border-box;
  margin: 10px 10px 0 0;
}

.Logo {
  grid-area: logo;
  height: 70px;
}

.Nav {
  display: flex;
  grid-area: nav;
  grid-template-columns: repeat(10, auto);
  align-items: center;
  justify-items: center;
  margin-top: 5px;
  position: relative;
  padding-right: 10px;
  margin-right: 40px;

  .ant-dropdown-trigger {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;
    margin-left: 2rem;
    //margin-right: 10px;
  }

  .icon-link-setting {
    //position: absolute;
    //right: 0;
    //top: 50%;
    //transform: translate(-50%, -50%);
    color: #fff;

  }

  > .selected {
    color: #feb034;
    border-bottom: 2px solid #feb034;

    > .anticon {
      color: #feb034;
    }
  }

  .link-download-app {
    font-size: 14px;
    background: #f1c40f;
    padding: 10px;
    color: #000;
    border-radius: 5px;

  }

  .link-download-app:hover {
    color: #0984e3;
  }
}

.Nav a {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
  margin-left: 1rem;
}

.Nav a:hover:not(a.link-icon) {
  transform: scale(1.1);
}

a.link-icon {
  padding-bottom: 5px;
}

.Nav button {
  padding: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  color: #fff;
  background-color: unset;
  border-radius: 10px;
  cursor: pointer;
  transition: 70ms;
}

.Nav button:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.Burger {
  display: none !important;
  grid-area: burger;
  margin: 0 20px 0 0;
  padding: 0;
  justify-self: end;
  font-size: 40px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
}

.Burger:active {
  transform: scale(1.2);
}

@media (max-width: 700px) {
  .header {
    grid-template-areas: "logo burger" "nav nav";
  }
  .Nav {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    grid-row-gap: 20px;
    padding: 30px 0 30px;
    background: rgba(40, 44, 47, 0.95);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    right: 0;
    top: 4rem;
    width: 17rem;
    z-index: 1000;

    > a {
      margin-left: 0;
    }

    > span.anticon {
      margin-left: 0
    }
  }
  .header-logo {
    margin-left: 1rem !important;
  }
  .Burger {
    display: inline !important;
  }
}

.NavAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}

.NavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}

.NavAnimation-exit {
  opacity: 1;
}

.NavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}

.header-logo {
  width: 250px;
  margin-left: 4rem;

  img {
    width: 100%;
  }
}
