.create-project-page {
      width: 100%;
      .wp-inner {
            margin: 4rem auto;

            .wp-form {
                  width: 70%;
                  margin: 0 auto;
                  border-radius: 5px;
                  background-color: #0c0c0c;
                  padding: 2rem ;
                  .title-form{
                        color: #fff;
                        text-transform: uppercase;
                        font-size: 26px;
                        margin-bottom: 3rem;
                  }
                  .ant-input-affix-wrapper {
                        background: #1a1a1a;
                        border-color: #1a1a1a;
                        border-radius: 5px;

                        .ant-input {
                              height: 40px;
                              outline: none !important;
                              box-shadow: none !important;
                              font-size: 16px;

                              &::placeholder {
                                    font-size: 14px;
                              }
                        }
                  }

                  .ant-input-prefix{
                        color: #fff;
                        font-size: 11px;
                        margin-right: 0.5rem;
                  }

                  .ant-picker{
                        width: 100%;
                        background: #1a1a1a;
                        border: none;
                        height: 50px;
                        outline: none  !important;
                        box-shadow: none !important;

                        .ant-picker-suffix{
                              .anticon{
                                    color: #feb034;
                              }
                        }
                        input{
                              color: #fff;
                              font-size: 16px;

                              &::placeholder{
                                    font-size: 14px;
                                    color: #808080;
                              }
                        }
                  }

                  .ant-input {
                        height: 50px;
                        outline: none !important;
                        box-shadow: none !important;
                        font-size: 16px;

                        &::placeholder {
                              font-size: 14px;
                              color: #808080 !important;
                        }
                  }

                  .ant-select-selector{
                        height: 50px;
                        background: #1a1a1a;
                        border: none;

                        .ant-select-selection-item{
                              line-height: 50px;
                              color: #fff;
                        }
                        .ant-select-selection-placeholder{
                              line-height: 50px;
                              color: #808080;
                        }
                  }

                  .ant-upload-list-picture-card-container{
                        width: 100%;
                        height: 150px;
                        border: none;
                        margin: 0 auto;

                  }
                  .ant-upload.ant-upload-select-picture-card{
                        width: 100%;
                        height: 200px;
                        background: #3c2d14;
                        border: 1px dashed #feb034;

                        .anticon{
                              color: #feb034;
                        }
                        svg{
                              font-size: 24px;
                        }
                        .ant-upload-list-item{
                              padding: 0;
                        }
                  }
                  .btn-form{
                        text-align: center;
                        button{
                              background: #feb034;
                              border: none;
                              outline: none;
                              box-shadow: none;
                              height: 50px;
                              width: 150px;
                              font-size: 16px;
                              font-weight: 500;
                              color: #fff;
                        }
                  }
            }
      }
}

.dropdown-time-project{
      .ant-picker-footer{
            .ant-picker-ok{
                  width: 100%;
                  button{
                        width: 100%;
                        background: #feb034;
                        border: none;
                  }
            }
      }
}
