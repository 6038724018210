.schedule{
    div.back{
        text-align: right;
        button.btn-back{
            background: none;
            border: none;
            color: #feb034;
            line-height: 0;
            position: relative;
            padding-left: 10px;
            font-weight: 600;
            > .anticon{
                font-size: 11px;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            > span{
                padding-left: 5px;
            }
        }
    }
    h6.title{
        position: relative;
        font-size: 18px;
        color: #fff;
        padding-left: 15px;
        .icon-info{
            position: absolute;
            top : 50%;
            transform: translate(-50%, -50%);
            color: #feb034;
            font-size: 13px;
            left: 0;
        }
    }
    > div:not(div.back):not(div:nth-child(5n)){
        padding: 20px;
        background: #0c0c0c;
        margin-bottom: 30px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
    .widget-info{
        .info-crew{
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding: 10px 20px;
            .info-top{
                display: flex;
                flex-wrap: nowrap;
                .thumb{
                    padding-top: 10px;
                    margin-right: 20px;
                }
                .info{
                    .name {
                        color: #fff;
                        padding: 10px 10px 5px 0;
                        margin: 0;
                        position: relative;
                        //.call-message{
                        //    display: inline-block;
                        //    > button{
                        //        background: none;
                        //        border: none;
                        //        padding: 4px 10px;
                        //        > .anticon{
                        //            font-size: 15px;
                        //            color : #feb034;
                        //        }
                        //    }
                        //    > button:last-child{
                        //        > .anticon{
                        //            color : #4cd137;
                        //        }
                        //    }
                        //    >  button:disabled{
                        //        > .anticon{
                        //            color : #535c68;
                        //        }
                        //    }
                        //}

                        > button{
                            background: none;
                            border: none;
                            padding: 4px 10px;
                            > .anticon{
                                font-size: 16px;
                                color : #feb034;
                            }
                        }
                        > button:last-child{
                            > .anticon{
                                color : #4cd137;
                            }
                        }
                        >  button:disabled{
                            > .anticon{
                                color : #535c68;
                            }
                        }
                    }

                    .username {
                        color: #808080;
                    }

                    div.rate {
                        padding-top: 5px;
                        color: #feb034;

                        .ant-rate {
                            font-size: 13px;
                        }
                    }
                }
            }

            .action{
                //min-width: 250px;
                text-align: right;
                p{
                    padding: 8px 20px;
                    background: #00a652;
                    color: #fff;
                    font-weight: 500;
                    display: inline-block;
                    border-radius: 20px;
                    text-align: center;
                }
                button{
                    display: block;
                    text-align: right;
                    color: #e93815;
                    border: 1px solid #e93815 ;
                    background: transparent;
                    height: 40px;
                    margin-top: 30px;
                }
            }
        }

        @media (max-width: 768px) {
            .info-crew {
                display: block;
                > .action{
                    text-align: center;
                }
            }
        }
    }
    .work-detail{
        .info-work{
            padding: 10px 20px;

            .item{
                display: flex;
                flex-wrap: nowrap;
                .title{
                    min-width: 200px;
                    max-width: 200px;
                    p{
                        color : #808080;
                        margin-bottom: 1rem;
                        line-height: 30px;
                        font-weight: 500;
                    }
                }
                > .detail{
                    p, a{
                        color : #fff;
                        display: block;
                        margin-bottom: 1rem;
                        line-height: 30px;
                        font-size: 16px;
                    }
                    p.attachment-name{
                        color: #1e90ff;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
                .detail-attachments{
                    margin-bottom: 20px;
                    > div{
                        margin-bottom: 10px;
                        .title-attachment{
                            > p {
                                margin-bottom: 5px;
                                > span {
                                    color: #808080;
                                    font-size: 14px;
                                    display: inline-block;
                                    padding-left: 20px;
                                }
                            }
                        }
                        .body-attachment{
                            > .list{
                                display: flex;
                                > .item{
                                    width: 32%;
                                    overflow: hidden;
                                    margin-right: 2%;
                                    .ant-image{
                                        > img{
                                            max-width: 100%;
                                            height: auto;
                                            object-fit: cover;
                                        }
                                        .ant-image-mask{
                                            .ant-image-mask-info{
                                                font-size: 11px;
                                                position: relative;
                                                padding-left: 15px;
                                                > .anticon{
                                                    position: absolute;
                                                    left: 0;
                                                    font-size: 9px;
                                                }
                                            }
                                        }
                                    }
                                }
                                > .item:nth-child(3n){
                                    margin-right: 0;
                                }
                            }
                            > .list-document-attachment{
                                display: block;
                                > .item {
                                    width: 100%;
                                    > a, p{
                                        color: #808080;
                                        text-decoration: underline;
                                        font-size: 14px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                    > .attachment-complete{
                        > .title-at{
                            display: inline-block;
                            > p{
                                margin-bottom: 5px;
                                font-size: 15px;
                                > span {
                                    color: #808080;
                                    font-size: 14px;
                                    display: inline-block;
                                    padding-left: 20px;
                                }
                            }
                        }
                        > .value-at{
                            display: inline-block;
                            > p, a{
                                margin-bottom: 5px;
                                color: #808080;
                                font-size: 14px;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            @media (max-width: 550px) {
                .item {
                    display: block;
                }
            }
        }
    }
    .time-schedule{
        padding: 30px 0;
        text-align: center;
        p{
            color: #808080;
        }
        h4{
            font-size: 30px;
            color: #fff;
        }
        button{
            background: #00a652;
            border: none;
            outline: none;
            height: 36px;
            padding: 0 50px;
            color: #fff;
            font-weight: 500;
            border-radius: 30px;
        }
    }
    .pay-appointment{
        text-align: center;
        background: transparent !important;
        box-shadow: none !important;
        padding-top: 0 !important;
        .btn-pay-appointment{
            background: #4cd137;
            border: none;
            font-weight: 500;
            color: #000;
        }
        .btn-pay-appointment:hover{
            color: #fff;
        }
    }
    .action-appointment{
        text-align: center;
        background: transparent !important;
        box-shadow: none !important;
        padding-top: 0 !important;
        display: flex;
        justify-content: center;
        > button{
            border: none;
            font-weight: 500;
            color: #000;
            height: 42px;
            width: 100%;
        }
        > button:hover{
            color: #fff;
        }
        .btn-decline-appointment {
            background: #e93815;
        }

        .btn-accept-appointment {
            background: #4cd137;
            margin-right: 20px;
        }
        .btn-accept-appointment{
            > button.ant-btn[disabled]{
                background: #4cd137;
            }
        }
    }
}

.schedule-page-detail{
    position: relative;
}