.modal-complete-appointment{
    .ant-modal-content{
        background: #0c0c0c;
        .ant-modal-body{
            background: #0c0c0c;
            .head-modal{
                display: flex;
                justify-content: space-between;
                > h5{
                    color: #fff;
                }
                > button{
                    background: none;
                    color: #feb034;
                    border: none;
                    height: 0;
                    padding: 0;
                    font-weight: 500;
                }
            }
            .body-modal {
                padding: 20px 0;
                #form-complete-appointment{
                    .type-complete-attachment{
                        .ant-space-item{
                            span, label{
                                color: #fff;
                            }
                            .ant-radio-wrapper{
                                .email-client{
                                    margin-bottom: 0;
                                }
                                .wp-attachment{
                                    margin-top: 20px;
                                    margin-left: 20px;
                                    .ant-upload{
                                        button{
                                            height: 0;
                                            padding: 0 20px;
                                        }
                                    }
                                    .upload{
                                        .upload-file-attachment{
                                            > .ant-col{
                                                > .ant-form-item-control-input{
                                                    min-height: 0;
                                                }
                                            }
                                            .btn-upload-attachment{
                                                position: absolute;
                                                top: -20px;
                                                left: 55px;
                                                > .anticon{
                                                    position: relative;
                                                    top: 50%;
                                                    left: 10px;
                                                    transform: translate(-50%, -15%);
                                                }
                                                > span{
                                                    color:  #feb034;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .btn-action-appointment{
                        text-align: center;
                        > button{
                            border: none;
                            color: #000;
                            font-weight: 500;
                        }
                        > button:hover{
                            color: #fff;
                        }
                        .btn-cancel{
                            background: #e74c3c;
                            margin-right: 10px;
                        }
                        .btn-complete{
                            background: #4cd137;
                        }
                    }
                }

            }
        }
    }
}