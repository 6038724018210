.modal-cash-out{
    > .ant-modal-content{
        background: #0c0c0c;
        > .ant-modal-body{
            > .body-modal{
                .content{
                    text-align: center;
                    h5, p{
                        color: #fff;
                    }
                    p {
                        > span{
                            display:inline-block;
                            padding-left: 10px;
                        }
                    }
                }
                .footer-modal{
                    text-align: center;
                    > button{
                        color: #000;
                        font-weight: 500;
                        border: none;
                    }
                    > button:first-child{
                        background: #feb034;
                        margin-right: 10px;
                    }
                    > button:last-child{
                        background: #4cd137;
                    }
                }
            }
        }
    }
}