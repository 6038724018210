.modal-cancel-appointment{
    .ant-modal-content{
        background-color: #0c0c0c;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    .ant-modal-body{
        background-color: #0c0c0c;
        padding: 20px 30px;
        border-radius: 5px;
    }
    .wp-cancel-appointment {
        .head-modal {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            button{
                color: #feb034;
                font-weight: 500;
                background: none;
                border: none;
                padding: 0;
            }
            .title {
                color: #fff;
                font-size: 18px;
                font-weight: 500;

                span {
                    font-size: 14px;
                    padding-left: 5px;
                }
            }
        }
        textarea::placeholder{
            color: #808080;
        }
        .btn-action-appointment{
            button{
                border-radius: 5px;
                background: #feb034;
                color: #000;
                font-weight: 500;
                border: none;
                outline: none;
                margin: 10px 0 20px 0;
            }
            button:first-child{
                margin-right: 20px;
            }
            button:last-child{
                background: #6b48ff;
            }
            button:hover{
                color: #fff;
                transition: color 0.5s;
            }
        }
    }
}