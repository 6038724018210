.appointment-page {
    > .appointment{
        .ant-tabs {
            .ant-tabs-nav::before {
                border-bottom: none;
            }
            .ant-tabs-tab + .ant-tabs-tab {
                margin: 0;
            }

            .ant-tabs-nav {
                .ant-tabs-ink-bar-animated {
                    max-width: 0;
                }


                .ant-tabs-nav-wrap {
                    width: 100%;
                    background: #0c0c0c;
                    border-radius: 30px;

                    .ant-tabs-nav-list {
                        width: 100%;

                        .ant-tabs-tab {
                            width: 50%;
                            text-align: center;
                            border-radius: 30px;

                            .ant-tabs-tab-btn {
                                width: 100%;
                                color: #808080;
                                font-weight: 500;

                            }
                        }

                        .ant-tabs-tab-active {
                            background: #feb034;

                            .ant-tabs-tab-btn {
                                color: #000;
                            }
                        }
                    }
                }
            }

            .ant-tabs-tabpane {
                > .item {
                    background: #0c0c0c;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    padding: 20px;
                    align-items: center;
                    margin-bottom: 15px;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                    .info-client , .info-crew {
                        .info-top {
                            display: flex;
                            flex-wrap: nowrap;

                            .thumb {
                                padding-top: 5px;
                                margin-right: 20px;
                                cursor: pointer;
                            }

                            .info {
                                p.appointment-id{
                                    color : #fff;
                                    //font-weight: 500;
                                    font-size: 14px;
                                    position: relative;
                                    padding: 10px 20px;
                                    margin: 0;
                                    > .anticon {
                                        color: #feb034;
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(-50%, -50%);
                                        left: 5px;
                                        font-size: 11px;
                                    }
                                }
                                p.name {
                                    color: #fff;
                                    font-weight: 500;
                                    margin: 0;
                                    padding-bottom: 5px;
                                    font-size: 16px;
                                    cursor: pointer;

                                    span {
                                        font-size: 14px;
                                        background: #00a652;
                                        display: inline-block;
                                        padding: 5px 10px;
                                        margin-left: 10px;
                                        border-radius: 20px;
                                    }
                                }

                                span.appointment-name {
                                    font-size: 14px;
                                    color: #808080;
                                    font-weight: 500;
                                }

                                .address {
                                    color: #fff;
                                    font-size: 14px;
                                    position: relative;
                                    margin: 0;
                                    padding: 10px 20px;

                                    .anticon {
                                        color: #feb034;
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(-50%, -50%);
                                        left: 5px;
                                        font-size: 11px;
                                    }
                                }
                            }
                        }

                        .info-bottom {
                            > p {
                                color: #808080;
                                //font-size: 14px;
                                line-height: 30px;
                                margin-right: 10px;
                                margin-bottom: 0;
                                margin-top: 10px;

                                a {
                                    color: #feb034;
                                    text-decoration: underline;
                                    display: inline-block;
                                    margin-left: 10px;
                                }
                            }
                            > button {
                                display: block;
                                border-radius: 5px;
                                border: none;
                                background: none;
                                color: #feb034;
                                font-weight: 500;
                                padding: 0;
                            }
                        }
                    }

                    .action {
                        min-width: 250px;
                        text-align: center;

                        p {
                            text-align: center;
                            color: #fff;
                            font-weight: 500;
                        }

                        button {
                            display: block;
                            border-radius: 5px;
                            border: none;
                            color: #fff;
                            min-width: 180px;
                            text-align: center;
                            font-weight: 500;
                            height: 40px;
                            margin: 15px auto;
                        }
                        button.detail{
                            background: #6b48ff;
                        }
                        button.success {
                            background: #00a652;
                        }

                        button.cancel {
                            background: #e93815;
                        }

                        button.accept {
                            background: #4cd137;
                        }
                        .accept{
                            button.ant-btn[disabled]{
                                background: #4cd137;
                            }
                        }

                        .duration{
                            display: flex;
                            justify-content: center;
                            > p {
                                margin-bottom: 0;

                            }
                            > p:first-child{
                                margin-right: 10px;
                            }
                        }
                    }
                }
                > .widget-empty-data{
                    background: #0c0c0c;
                    > .empty-data{
                        padding: 50px;
                    }
                }
                @media (max-width: 576px) {
                    .item {
                        display: block;
                        .info-client , .info-crew{
                            margin-bottom: 10px;
                            .info-bottom{
                                display: none;
                            }
                        }
                    }
                }
            }

        }
    }
}

.loading-services{
    position: absolute !important;
    right: 50%;
    transform: translate(-50%, -50%);
}