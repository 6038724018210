.notification-page{
    background: #0c0c0c;
    height: 100vh;
    > .notification{
        padding: 30px;
        h6.title{
            color: #fff;
            font-size: 20px;

        }
        .list-notification{
            padding: 10px 20px;
            .item{
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-bottom: 10px;
                padding: 10px 20px;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
                .content{
                    display: flex;
                    flex-wrap: nowrap;
                    .thumb{
                        padding-top: 5px;
                        margin-right: 20px;
                    }
                    .description{
                        p.title{
                            color : #fff;
                            margin-bottom: 10px;
                            cursor: pointer;
                            font-size: 15px;
                        }
                        p.desc{
                            color: #808080;
                            font-size: 14px;
                        }
                        p.time{
                            color: #808080;
                            font-size: 13px;
                        }
                    }
                }
                .action{
                    min-width: 100px;
                    text-align: center;

                }

            }
            .item:hover{
                //background: #808080;

                .content{
                    .description{
                        p.title{
                            color: #feb034;
                        }
                        p.time{
                            color: #fff;
                        }
                    }
                }
            }
            .empty-data{
                .ant-empty-description{
                    color: #808080;
                    padding: 10px 0;
                }
            }
        }

    }
}
.ant-dropdown.dropdown-action-notification{
    .ant-dropdown-menu{
        background: #808080;
        .ant-dropdown-menu-item{
            a{
                color: #000;
                //line-height: 24px;
                padding: 5px 20px;
                position: relative;
                .anticon{
                    position: relative;
                    top: 50%;
                    font-size: 9px;
                    left: 0;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .ant-dropdown-menu-item:hover{
            background: none;
            a{
                text-decoration: none;
                color: #fff;
            }
        }
    }
}