.service-list-page {
    background: #0c0c0c;
    border-radius: 10px;
    padding-bottom: 5rem;
    > .list-services{
        padding: 3rem;

        .header-widget {
            margin-bottom: 30px;
            h3 {
                color: #fff;
            }

            button {
                background: none;
                border: none;
                color: #feb034;
                position: relative;
                font-size: 16px;
                height: 0;

                > .anticon {
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    padding-left: 10px;
                    font-size: 16px;
                }
            }
        }
        > .services {
            > .item-service{
                background: #1d1d1d;
                padding: 15px 20px;
                border-radius: 5px;
                //box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
                margin-bottom: 20px;
                > .content-service{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .content{
                        display: flex;
                        align-items: center;
                        .thumb{
                            margin-right: 20px;
                        }
                        .detail{
                            p{
                                margin-bottom: 5px;
                                color: #fff;
                            }
                            p.title{
                                font-size: 18px;
                                font-weight: 500;

                            }
                            span.price{
                                font-size: 14px;
                                background: #00a652;
                                padding: 3px 15px;
                                border-radius: 20px;
                                display: inline-block;
                                margin-left: 20px;
                            }
                            p.equipment{
                                color: #808080;
                                font-size: 14px;
                                margin-bottom: 10px;
                            }
                            div.info-price{
                                padding: 5px 0;
                                > div.item-info{
                                    display: flex;
                                    div.title > p , div.value > p{
                                        font-size: 14px;
                                        margin-bottom: 10px;
                                    }
                                    div.title{
                                        min-width: 200px;
                                    }
                                }
                            }
                        }
                    }
                    .action{
                        button{
                            border: none;
                            padding: 0 5px;
                            background: none;
                        }
                        button.edit{
                            color: #feb034;
                        }
                        button.delete{
                            color: #e93815;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 650px) {
    .service-list-page{
        .list-services{
            > .services {
                > .item-service{
                    > .content-service{
                        display: block;
                        .action{
                            text-align: center;
                        }
                    }

                }
            }
        }
    }
}
@media screen and (max-width: 550px) {
    .service-list-page{
        .list-services{
            > .services {
                > .item-service{
                    > .content-service{
                        display: block;
                        .content{
                            .detail{
                                div.info-price{
                                    > div.item-info{
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}