.profile-page {
    > .profile{
        background: #0c0c0c;
        padding: 30px 30px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        .header-profile {
            display: flex;
            //flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: 20px;
            > div {
                margin-right: 50px;
            }

            > div:last-child {
                margin-right: 0;
            }

            .info-profile {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;

                .avatar-profile {
                    .avatar-uploader {
                        margin-right: 20px;

                        .ant-upload {
                            border-radius: 50%;
                            border: none;
                            position: relative;
                        }

                        .ant-upload.ant-upload-select-picture-card {
                            width: auto;
                            height: auto;
                        }
                    }

                    .icon-upload {
                        position: absolute;
                        font-size: 16px;
                        background: #feaf34;
                        right: 5px;
                        padding: 5px;
                        color: black;
                        border-radius: 50%;
                        top: 0;
                    }
                }

                .info {
                    .name {
                        color: #fff;
                        padding: 10px 0 5px;
                        margin: 0;
                    }

                    .username {
                        color: #808080;
                        font-size: 14px;
                    }

                    div.rate {
                        color: #feb034;
                        .ant-rate {
                            font-size: 12px;
                        }
                    }

                    .address {
                        position: relative;
                        color: #fff;
                        padding: 10px 20px;
                        font-size: 14px;
                        margin-bottom: 0;

                        > .icon-address {
                            font-size: 11px;
                            position: absolute;
                            left: 5px;
                            top: 45%;
                            transform: translate(-50%, -50%);
                            color: #feb034;
                        }
                    }
                }
            }

            .info-work {
                .statistical {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;

                    div {
                        margin-right: 30px;
                        margin-bottom: 1rem;
                        p {
                            color: #fff;
                            text-transform: uppercase;
                            font-size: 26px;
                            text-align: center;
                            font-weight: 500;
                            margin-bottom: 0.75rem;
                        }
                    }

                    div:last-child {
                        margin-right: 0;
                    }
                    .exp-crew{
                        padding-bottom: 12px;
                        position: relative;
                        .icon-edit-exp{
                            position: absolute;
                            color: #fff;
                            top: 0;
                            right: 0;
                            z-index: 1000;
                            font-size: 11px;
                            cursor: pointer;
                        }
                        .inp-exp{
                            background: transparent !important;
                            font-size: 26px;
                            font-weight: 500;
                            text-align: center;
                        }
                        .inp-exp:focus, .ant-input-focused {
                            border-color: #40a9ff;
                            border-right-width: 1px !important;
                            outline: 0;
                            box-shadow: none !important;
                        }
                    }
                }

                @media (max-width: 620px) {
                    .statistical{
                        div{
                            p{
                                font-size: 20px;
                            }
                        }

                    }
                }
                @media (max-width: 576px) {
                    .statistical{
                        div{
                            margin-right: 15px;
                            p{
                                font-size: 18px;
                            }
                        }
                        div:last-child {
                            margin-right: 0;
                        }
                    }
                }

                @media (max-width: 476px) {
                    .statistical{
                        div{
                            p{
                                font-size: 16px;
                            }
                        }
                    }
                }
                @media (max-width: 400px) {
                    .statistical{
                        div{
                            p{
                                font-size: 14px;
                            }
                        }
                    }
                }

                .edit-profile {
                    button {
                        background-color: transparent;
                        border: 1px solid #feb034;
                        //width: 100%;
                        color: #feb034;
                        border-radius: 20px;
                        margin: 0 2%;
                    }
                    button.edit-service-btn{
                        border: 1px solid #4cd137;
                        color: #4cd137;
                    }
                }
            }
        }

        .body-profile {
            padding-bottom: 50px;
            p.title {
                position: relative;
                padding-left: 20px;
                color: #fff;
                font-size: 1rem;

                > .icon-info {
                    font-size: 12px;
                    position: absolute;
                    left: 5px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: #feb034;
                }
            }

            > div {
                min-height: 200px;
                padding: 30px 50px;
                background-color: transparent;
                box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125);
                border: 1px solid #1c1c1c;
            }

            .about-me {
                margin-bottom: 30px;
                p.title{
                    color: #fff;
                    font-size: 1rem;
                }
                div{
                    padding-left: 2rem;
                    p {
                        color: #808080;
                        line-height: 28px;
                    }
                }

            }
            @media (max-width: 576px) {
                .about-me{
                    padding : 30px 10px;
                    div{
                        padding-left: 10px;
                    }
                }
            }
            .portfolio {
                > .header-widget{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    margin-bottom: 10px;
                    > button{
                        background: none;
                        color: #feb034;
                        padding: 0 3px;
                        height: 24px;
                        border: none;
                        font-size: 20px;
                        line-height: 0;
                    }
                }
                margin-bottom: 30px;
                > .widget{
                    > .title{
                        margin-bottom: 0;
                        padding-left: 10px;
                        color: #808080;
                    }
                    > .list {
                        display: flex;
                        padding: 10px 15px;
                        flex-wrap: wrap;

                        > .item {
                            //padding: 10px;
                            font-size: 30px;
                            text-align: center;
                            width: 24%;
                            height: 150px;
                            overflow: hidden;
                            margin-bottom: 20px;
                            margin-right: 1.33%;
                            position: relative;
                            > .ant-image{
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                img{
                                    max-width: 100%;
                                    max-height: 100%;
                                    object-fit : cover;
                                }
                                .ant-image-mask{
                                    .ant-image-mask-info{
                                        font-size: 13px;
                                        position: relative;
                                        padding-left: 15px;
                                        > .anticon{
                                            position: absolute;
                                            left: 0;
                                            font-size: 11px;
                                        }
                                    }
                                }
                            }
                            > img{
                                max-width: 100%;
                                max-height: 100%;
                                object-fit : cover;
                                cursor: pointer;
                            }
                            > video{
                                max-width: 100%;
                                max-height: 100%;
                                object-fit: cover;
                                cursor: pointer;
                                position: relative;
                            }
                            .anticon.open-model{
                                position: absolute;
                                top: 40%;
                                transform: translate(-50% , -50%);
                                left: 50%;
                                color: #fff;
                                font-size: 13px;
                                opacity: 1;
                            }
                            video:hover, img:hover{
                                opacity: 0.5;
                            }
                            .btn-delete-portfolio{
                                position: absolute;
                                top: 0;
                                right: 5px;
                                color: #fff;
                                font-size: 13px;
                                background: none;
                                border: none;
                            }
                        }
                        > .item:nth-child(4n){
                            margin-right: 0;
                        }
                    }
                    .list-image{
                        > .item {
                            font-size: 30px;
                            text-align: center;
                            height: 200px;
                            overflow: hidden;
                            position: relative;
                        }
                    }
                }
            }

            @media (max-width: 576px) {
                .portfolio{
                    padding : 30px 10px;
                    div{
                        padding-left: 10px;
                    }
                }
            }

            .contact-info {
                div {
                    padding-left: 2rem;
                    p {
                        position: relative;
                        padding-left: 20px;
                        color: #fff;
                        font-size: 15px;

                        > .icon-contact {
                            font-size: 11px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: #feb034;
                        }
                    }
                }
            }
            @media (max-width: 576px) {
                .contact-info{
                    padding : 30px 10px;
                    div{
                        padding-left: 10px;
                    }
                }
            }
            .empty-data {
                .ant-empty-normal{
                    margin: 10px;
                    .ant-empty-image{
                        .ant-empty-img-simple{
                            width: 30px;
                            height: 25px;
                        }
                    }
                    .ant-empty-description{
                        color: #808080;
                    }
                }

            }

        }

        @media screen and (max-width: 1065px) {
            .header-profile{
                display: block;
                .info-profile{
                    margin-bottom: 10px;
                }
            }
        }
    }


}

// modal-add-portfolio

.ant-modal.modal-add-portfolio{
    .ant-modal-content{
        .ant-modal-body{
            background: #0c0c0c;
            .header-modal{
                justify-content: space-between;
                align-items: center;
                p.title{
                    font-size: 16px;
                    color: #fff;
                }
                button{
                    display: block;
                    background: none;
                    border: none;
                    color: #feb034;
                    font-weight: 500;
                }
            }
            .body-modal{
                form{
                    .wp-inner{
                        .head-wp{
                            .title{
                                font-size: 16px;
                                color: #fff;
                            }
                        }
                        .body-wp{
                            input{
                                background: #1a1a1a;
                                border: none;
                                color: #fff;
                                padding: 8px 15px;
                            }
                            input:hover{
                                background: #1a1a1a;
                            }
                            input::placeholder{
                                color: #808080;
                            }
                            .ant-form-item-label > label{
                                color: #808080;
                            }
                            .ant-upload-list-item-name{
                                color: #808080;
                            }
                        }
                    }
                }
                #btn-add-portfolio{
                    font-weight: 500;
                    background: #feb034;
                    border: none;
                    color: #000;
                    margin: 10px 0 0 0;
                }

            }
            .footer-modal{
                padding: 10px 0 20px 0;
                border-top: 1px solid #3c3c3c;
                .note{
                    p , li{
                        color: #808080;
                        margin-bottom: 5px;
                    }
                    .detail-note{
                        padding: 0 20px;
                        ul{
                            padding: 0 20px;
                        }
                    }
                }
            }
        }
    }

    .ant-upload-list-picture-card-container{
        width: 30%;
        border: none;
    }
    .ant-upload.ant-upload-select-picture-card{
        width: 30%;
        background: #3c2d14;
        border: 1px dashed #feb034;

        .anticon{
            color: #feb034;
        }
        svg{
            font-size: 24px;
        }
        .ant-upload-list-item{
            padding: 0;
        }
    }
}

.w-45{
    width: 45%;
}