.modal-tip-appointment{
    > .ant-modal-content{
        background: #0c0c0c;
        > .ant-modal-body{
            background: #0c0c0c;
            min-height: 390px;
            .head-modal{
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                > h5{
                    color: #fff;
                }
                > button{
                    background: none;
                    color: #feb034;
                    border: none;
                    height: 0;
                    padding: 0;
                    font-weight: 500;
                }
            }
            .body-modal {
                > .avatar{
                    text-align: center;
                    margin-bottom: 20px;
                    > p.name-crew{
                        color: #fff;
                        margin-bottom: 0;
                        font-size: 16px;
                        margin-top: 10px;
                    }
                }
                .head-row{
                    text-align: center;
                    > p{
                        color: #fff;
                    }
                }
                .tip-row{
                    .price-tip{
                       position: relative;
                        > p{
                            position: absolute;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: #4cd137;
                            margin-bottom: 0;
                            left: 50%;
                        }
                    }
                }
                .button-tip{
                    text-align: center;
                    margin-top: 20px;
                    button{
                        margin-right: 20px;
                        background: #feb034;
                        color: #000;
                        font-weight: 500;
                        border: none;
                    }
                    button.btn-pay-now{
                        background: #44bd32;
                    }
                }
            }
        }
    }
}

