@mixin info {
  border: 1px solid rgba(128, 128, 128, 0.47);
  padding: 0 2rem;
  border-radius: 5px;
  background-color: #0c0c0c;
  width: 100%;
}

.home-page {
  width: 100%;

  button {
    color: #000;
    border-radius: 5px;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    background: #feb034;
    padding: 10px;
    max-width: 250px;

    img {
      height: 20px;
    }
  }

  .information {
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 2rem;

    .sub-title {
      font-size: 20px;
      font-weight: 600;
      color: #feb034;
      text-transform: uppercase;
    }

    .bottom-info {
      @include info;
      padding: 2rem;

      .bottom-right {
        margin-top: 7.5rem;
      }

      .sub-value {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
      }

      .anticon svg {
        color: #ffffff;
        cursor: pointer;
        font-size: 12px;
      }
    }

    .top-info {
      @include info;

      .info-top {
        width: 100%;
        padding: 1rem 0;
        border-bottom: 1px solid rgba(128, 128, 128, 0.47);
        text-align: center;

        p {
          color: #ffffff;
        }

        p.name {
          font-size: 18px;
          font-weight: 500;
          color: #fff;
        }

        p:not(p.name) {
          padding-left: 10px;
          position: relative;

          > .anticon {
            color: #feb034;
            font-size: 15px;
            position: absolute;
            top: 45%;
            padding-right: 2rem;
            transform: translate(-50%, -50%);
          }
        }
      }

      .info-bottom {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .ant-space-horizontal {
          &:first-child {
            border-right: 1px solid rgba(128, 128, 128, 0.47);
          }

          width: 50%;
        }

        .sub-value {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }

  .banner-slide {
    .slick-slide img {
      object-fit: contain;
    }

    .button-find {
      display: flex;
      justify-content: center;
      gap: 10px;


    }

    .slogan {
      text-align: center;
      padding: 1rem 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      .slogan-title {
        font-size: 40px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        color: #ffffff;
        margin: 1rem 0;
      }

      .slogan-content {
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        line-height: 25px;

        strong {
          color: #ffffff;
          font-weight: bold;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home-page {
    .banner-slide {
      .slick-slide img {
        height: 500px;
        object-fit: cover;
      }

      .button-find {
        flex-direction: column;

        button {

          img {
            height: 20px;
          }
        }
      }

      .slogan {
        .slogan-title {
          font-size: 25px;
        }

        .slogan-content {
          font-size: 16px;
        }
      }
    }

    .information {
      flex-direction: column;
      gap: 10px;

      .info-bottom {
        padding: 2rem 0;
        flex-direction: column;

        .ant-space-horizontal {
          width: 100% !important;

          img {
            width: 50px;
          }

          &:first-child {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(128, 128, 128, 0.47);
            border-right: unset !important;
          }

        }
      }

      .bottom-info {
        .bottom-wrapper {
          flex-direction: column;

          .bottom-left, .bottom-right {
            width: 100% !important;
            margin-top: 2rem !important;
          }
        }
      }

      .top-info {
        img {
          max-width: 250px;
        }
      }
    }
  }
}
