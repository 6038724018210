.card-history-payment-page{
    > .payment{
        .ant-tabs {
            .ant-tabs-nav::before {
                border-bottom: none;
            }
            .ant-tabs-tab + .ant-tabs-tab {
                margin: 0;
            }

            .ant-tabs-nav {
                .ant-tabs-ink-bar-animated {
                    max-width: 0;
                }


                .ant-tabs-nav-wrap {
                    width: 100%;
                    background: #0c0c0c;
                    border-radius: 30px;

                    .ant-tabs-nav-list {
                        width: 100%;

                        .ant-tabs-tab {
                            width: 50%;
                            text-align: center;
                            border-radius: 30px;

                            .ant-tabs-tab-btn {
                                width: 100%;
                                color: #808080;
                                font-weight: 500;

                            }
                        }

                        .ant-tabs-tab-active {
                            background: #feb034;

                            .ant-tabs-tab-btn {
                                color: #000;
                            }
                        }
                    }
                }
            }

            .ant-tabs-tabpane {
                background: #0c0c0c;
                box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
                min-height: 250px;
                text-align: center;
                padding: 30px 20px;

                .list-card{
                    .head-list{
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;
                        p.title{
                            font-size: 18px;
                            color: #fff;
                            font-weight: 500;
                            margin-bottom: 0;
                        }
                        button{
                            background: none;
                            border: none;
                            color: #feb034;
                            font-size: 18px;
                            padding: 0;
                        }
                    }
                    .body-list{
                        text-align: left;

                        > .item{
                            margin-bottom: 10px;
                            .wp-card{
                                background: #5bd1d7;
                                padding: 10px 10px;
                                display: flex;
                                justify-content: space-between;
                                .content{
                                    display: flex;
                                    .thumb{
                                        margin-right: 20px;
                                        .anticon{
                                            font-size: 20px;
                                        }
                                    }
                                    .detail{
                                        p{
                                            margin-bottom: 0;
                                            font-weight: 600;
                                        }

                                    }
                                }
                                .action{
                                    button{
                                        color: red;
                                        border: none;
                                        background: none;
                                    }
                                }
                            }

                        }
                        > .item:nth-child(2n){
                            margin-right: 0;
                        }
                    }
                    .body-list-history{
                        > .item {
                            padding: 10px 20px;
                            background: #41b6e6;
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: nowrap;
                            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                            align-items: center;
                            border-radius: 5px;
                            margin-bottom: 10px;
                            > .detail{
                                .row-item{
                                    display: flex;
                                    p{
                                        margin-bottom: 5px;
                                    }
                                    > .title{
                                        min-width: 100px;
                                        text-align: left;

                                        > p{
                                            color: #000;
                                            font-weight: 500;
                                        }
                                    }
                                    > .value{
                                        color: #fff;
                                        font-weight: 500;
                                        > p.name-crew{
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                            .price-payment{
                                margin-right: 20px;
                                > p{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}