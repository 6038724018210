.modal-show-pdf{
    > .ant-modal-content{

        .head-modal{
            z-index: 1000;
            text-align: right;
            > button{
                background: none;
                color: #feb034;
                border: none;
                height: 0;
                padding: 0;
                font-weight: 600;
                font-size: 13px;
            }
        }
        .wp-pdf {
            position: relative;
            .react-pdf__Page__canvas{
                //width: 100% !important;
                //height: 100% !important;
            }
            .page-controls {
                position: absolute;
                bottom: 2%;
                left: 50%;
                background: white;
                //opacity: 0;
                transform: translateX(-50%);
                transition: opacity ease-in-out 0.2s;
                box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
                border-radius: 4px;
                > button{
                    width: 44px;
                    height: 44px;
                    background: white;
                    border: 0;
                    font-size: 0.8em;
                    > .anticon{
                        font-size: 10px;
                    }
                }
                > span{
                    font-size: 0.8em;
                    padding: 0 0.5em;
                    font-weight: 500;
                }
            }
        }
    }
}

.react-pdf {
    &__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__Page {
        //max-width: calc(~"100% - 2em");
        //box-shadow: 0 0 8px rgba(0, 0, 0, .5);
        margin: 1em;

        canvas {
            max-width: 100%;
            height: auto !important;
        }
    }

    &__message {
        padding: 20px;
        color: #000;
        font-weight: 500;
    }
}