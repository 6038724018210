.modal-check-verify-cash-out{
    > .ant-modal-content{
        background: #0c0c0c;
        > .ant-modal-body{
            > .head-modal{
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                > h5 {
                    color: #fff;
                }
                > button{
                    background: none;
                    border: none;
                    color: #feb034;
                    height: 0;
                    padding: 0;
                    font-size: 13px;
                    font-weight: 500;
                }
            }
            > .body-modal{
                > .content{
                    > p{
                        color: #fff;
                    }
                }
            }
            .footer-modal{
                text-align: center;
                > button{
                    display: block;
                    color: #000;
                    font-weight: 500;
                    border: none;
                    width: 100%;
                    margin-bottom: 10px;
                    &:hover{
                        color: #fff;
                    }
                }
                > button:first-child{
                    background: #feb034;
                }
                > button:nth-child(2n){
                    background: #00a8ff;
                    margin-right: 10px;
                }
                > button:last-child{
                    background: #4cd137;
                }
            }
        }
    }
}