.modal-add-bank-account{
    > .ant-modal-content{
        background: #0c0c0c;
        //max-height: 80vh;
        //overflow: auto;
        > .ant-modal-body{
            > .head-modal{
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                margin-bottom: 15px;
                > h5 {
                    color: #fff;
                    text-transform: uppercase;
                }
                > button{
                    background: none;
                    border: none;
                    color: #feb034;
                    height: 0;
                    padding: 0;
                    font-size: 13px;
                    font-weight: 500;
                }
            }
            > .body-modal{
                #form-add-bank-account{
                    .ant-form-item{
                        > .ant-form-item-label{
                            label{
                                color: #fff;
                            }
                        }
                        input{
                            padding: 7px 12px;
                        }
                        input::placeholder{
                            color: #808080;
                        }

                        .ant-input-number{
                            width: 100%;
                            background: #1a1a1a;
                            border: none;
                            color: #fff;
                        }
                    }
                    .btn-submit-add-bank{
                        margin-top: 10px;
                        height: 38px;
                        background: #feb034;
                        color: #000;
                        font-weight: 500;
                        border: none;
                    }
                    .title-input-birthday{
                        margin-bottom: 5px;
                        color: #fff;
                        font-weight: 500;
                    }
                    .btn-skip{
                        text-align: center;
                        button{
                            background: none;
                            border: none;
                            color: #808080;
                           > span{
                               padding-bottom: 5px;
                               text-decoration: underline;
                           }
                        }
                    }
                }
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}