.modal-edit-profile{
    .ant-modal-body{
        background: #0c0c0c;
        .header-modal{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: 20px;
            h4.title{
                color: #fff;
                padding:  0 10px;
            }
            button{
                background: none;
                color: #feb034;
                border: none;
                font-weight: 500;
            }
        }
        .form-update{
            #normal_form_update_profile{
                .ant-input-affix-wrapper{
                    background: #1a1a1a;
                    border: none;
                    border-radius: 20px;
                    .ant-input-prefix{
                        .anticon{
                            font-size: 11px;
                            color: #fff;
                        }
                    }
                }
                input{
                    height: 32px;
                    color: #fff;
                    background: #1a1a1a;
                    padding: 0 10px;
                }
                input::placeholder{
                    color: #808080;
                }
                #btn-completed-profile{
                    background: #feb034;
                    color: #000;
                    font-weight: 500;
                    border: none;
                    margin: 10px 0 30px 0;

                }
                .select-type-user{
                    padding-left: 10px;
                    > .title-choose-role{
                        color: #fff;
                        font-weight: 500;
                        margin-bottom: 5px;
                        position: relative;
                        padding-left: 20px;
                        font-size: 16px;
                        > .anticon{
                            position: absolute;
                            font-size: 13px;
                            top: 45%;
                            transform: translate(-50%, -50%);
                            left: 5px;
                        }
                    }
                    .cw-checkbox-role{
                        > label.ant-checkbox-wrapper{
                            margin-right: 50px;
                            span{
                                color: #fff;
                            }
                            .ant-checkbox-inner{
                                border-radius: 50%;
                            }
                        }
                    }
                }
                .status-vaccine  {
                    .ant-select {
                        .ant-select-selector {
                            background: #1a1a1a!important;
                            border: none;
                            border-radius: 20px;
                            height: 40px;
                            text-align: left;
                            margin-bottom: 10px;
                            > .ant-select-selection-placeholder{
                                line-height: 40px;
                                padding-left: 24px;
                                color: grey;
                            }
                            .ant-select-selection-item{
                                color: #fff;
                                padding: 0 24px;
                                line-height: 40px;
                            }
                        }
                        .ant-select-arrow{
                            right: 20px;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            color: grey;
                        }
                    }

                }
                .icon-vaccine{
                    position: absolute;
                    top: 22%;
                    transform: translate(-50%, -50%);
                    left: 16px;
                    span.anticon{
                        font-size: 11px;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.custom-notify-message-success.ant-notification-notice{
    padding: 10px 20px;
    .anticon.ant-notification-notice-icon-success {
        color: #52c41a;
        font-size: 13px;
    }
    .anticon.ant-notification-notice-icon-warning {
        font-size: 13px;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message {
        margin-bottom: 4px;
        margin-left: 36px;
        font-size: 16px;
    }
    .ant-notification-notice-close {
        position: absolute;
        top: 45%;
        transform: translate(-50%, -50%);
        .anticon{
            font-size: 12px;
        }
    }
}