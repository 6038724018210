.empty-data {
    .ant-empty-normal {
        margin: 10px;

        .ant-empty-image {
            .ant-empty-img-simple {
                width: 25px !important;
                height: 25px;
            }
        }

        .ant-empty-description {
            color: #feb034;
            font-size: 13px;
            font-weight: 500;
        }
    }
}
