.modal-result-success {
    > .ant-modal-content {
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        background: #0c0c0c;

        .ant-modal-body {
            text-align: center;
            border-radius: 10px;

            p {
                color: #fff;
                font-weight: 500;
                font-size: 18px;
                padding: 10px 0;
            }
            button{
                background: #feb034;
                border: none;
                color: #000;
                font-weight: 500;
            }
            .anticon{
                font-size: 20px;
                padding: 10px;
                border-radius: 50%;
                margin: 20px 0;
            }
            .anticon-check{
                color: #fff;
                background: #34fe51;
            }
            .anticon-close{
                color: #fff;
                background: #e84118;
            }
        }
    }
}