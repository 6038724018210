.page-verification-email{
    padding: 2rem 4rem 0 4rem;
    width: 60% !important;
    .verification-email{

        > .head-widget{
            text-align: right;
            button{
                background: none;
                color: #feb034;
                border: none;
                font-weight: 500;
                margin-bottom: 30px;
                > span{
                    text-decoration: underline;
                }
            }
        }
        > .thumb-verify{
            text-align: center;
            > img{
                width: 80px;
                height: auto;
            }
            > p{
                margin-top: 1rem;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
            }
        }
        > .content-verify{
            padding: 20px 10px;
            > p{
                color: #fff;
                //text-transform: uppercase;
                line-height: 24px;
            }
        }
        > .footer-verify{
            text-align: center;
            > button{
                display: block;
                width: 100%;
            }
            > button:first-child{
                background: #feb034;
                height: 38px;
                border: none;
                font-weight: 500;
                margin-bottom: 20px;
            }
            button:last-child{
                background: none;
                border: none;
                color: #feb034;
                span{
                    text-decoration: underline;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .page-verification-email {
        padding: 2rem 0;
        width: 70% !important;
    }
}

@media screen and (max-width: 576px) {
    .page-verification-email {
        padding: 2rem 0;
        width: 80% !important;
    }
}