.modal-final-pay-appointment{
    .ant-modal-content{
        background: #0c0c0c;
        .ant-modal-body{
            background: #0c0c0c;
            .head-modal{
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                > h5{
                    color: #fff;
                }
                > button{
                    background: none;
                    color: #feb034;
                    border: none;
                    height: 0;
                    padding: 0;
                    font-weight: 500;
                }
            }
            .body-modal {
                > .avatar{
                    text-align: center;
                    margin-bottom: 20px;
                   > p.name-crew{
                       color: #fff;
                       margin-bottom: 0;
                       font-size: 16px;
                       margin-top: 10px;
                   }
                }
                #form-final-payment{
                    > .ant-form-item{
                        > .ant-form-item-label{
                            label{
                                color: #fff;
                            }
                        }
                        input{
                            padding: 8px 10px;
                            color: #fff;
                            font-size: 14px;
                        }
                        input::placeholder{
                            color: #808080;

                        }
                    }
                    > .ant-form-item.rate-service{
                        text-align: center;
                        > .ant-form-item-label{
                            text-align: center;
                            label{
                                font-size: 16px;
                            }
                        }
                        .ant-rate{
                            .ant-rate-star{
                                font-size: 16px;
                            }
                        }

                    }
                    .price-appointment{
                        p {
                            color: #808080;
                        }
                    }
                    .button-pay{
                        text-align: center;
                        button{
                            margin-right: 20px;
                            background: #feb034;
                            color: #000;
                            font-weight: 500;
                            border: none;
                        }
                        button.btn-pay-now{
                            background: #44bd32;
                        }
                    }
                }
            }
        }
    }
}

