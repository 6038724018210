.earning-page{
    > .earning{
        height: 100%;
        padding-bottom: 50px;
        > div{
            background: #0c0c0c;
            margin-bottom: 30px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            padding: 30px 20px;
        }
        .charts {
            .date-earning{
                padding: 30px 40px;
                display: flex;
                justify-content: space-between;
                h6{
                    color: #fff;
                    text-align: center;
                    margin: 0;
                }
                > button{
                    background: none;
                    border: none;
                    //height: 0;
                    margin: 0;
                    padding: 0;
                    line-height: 0;
                    > .anticon{
                        color: #fff;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 600;
                    }
                    .anticon.anticon-left{
                        //padding-left: 30px;
                    }
                    .anticon.anticon-right{
                        //padding-right: 30px;
                    }
                }
            }
            .chart{
                .chart-custom-earning{

                }
            }
        }
        .total-this-week{
            display: flex;
            justify-content: space-between;
            padding: 0 40px;
            > p{
                margin-bottom: 0;
                padding: 20px 0;
                font-weight: 500;
            }
            > p:first-child{
                color: #808080;
            }
            > p:last-child{
                font-size: 20px;
                color: #4cd137;
            }
        }
        .cash-out-now{
            > div {
                display: flex;
                padding: 0 20px;
                justify-content: space-between;
                > .content-cash-out{
                    > p{
                        margin-bottom: 0;
                        color: #fff;
                    }
                    > p.amount{
                        text-align: center;
                        margin-top: 10px;
                    }
                }
                > button{
                    background: #4cd137;
                    border: none;
                    font-weight: 500;
                    color: #000;
                }
                > button.btn-cash-out-disable{
                    background: #808080;
                }
            }
        }
        .total-payout{
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div{
                text-align: center;
                width: 33%;
                padding: 0 20px;
                p{
                    color: #808080;
                    font-weight: 500;
                }
                p.amount{
                    color: #fff;
                    font-size: 20px;
                    margin-bottom: 0;
                }
            }
            > div:nth-child(2n){
                p.amount{
                    color: #00a652;
                }
            }
            > div:first-child{
                text-align: left;
            }
            > div:last-child{
                text-align: right;
            }
        }
        .transactions{
            padding: 0;
            > .header-widget{
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                padding: 20px 30px;
                p{
                    color: #fff;
                    margin-bottom: 0;
                }
                border-top: 1px solid #333333;
                border-right: 1px solid #333333;
                border-left: 1px solid #333333;
            }
            .ant-collapse{
                background: #0c0c0c;
                border: 1px solid #333333;
                .ant-collapse-item{
                    border-bottom: 1px solid #333333;
                    padding: 0 30px;
                }
                .ant-collapse-header{
                    position: relative;
                    .anticon{
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        right: 0;
                        color: #fff;
                    }
                    .title-collapse{
                        > .head-col{
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 5px;
                            > p.time {
                                font-weight: 500;
                                color: #fff;
                                margin-bottom: 0;
                                > span{
                                    display: inline-block;
                                    padding-left: 10px;
                                    color: #808080;
                                }
                            }
                            > button{
                                background: none;
                                border: none;
                                height: 0;
                                padding: 0;
                                margin: 0;
                                font-weight: 500;
                                color: #fbc531;
                                > span{
                                    text-decoration: underline;
                                }
                            }
                            > button.no-cash-out{
                                color: #808080;
                            }
                        }
                        > .desc-col {
                            > div{
                                display: flex;
                                > p{
                                    color: #808080;
                                    margin-bottom: 5px;
                                }
                                > p:first-child{
                                    min-width: 70px;
                                }

                                > p.notify-day-cash{
                                    color: #e84118;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .ant-collapse-content{
                    background: #0c0c0c;
                    border-top: 1px solid #333333;
                    padding: 0 20px;
                    .ant-collapse-content-box{
                        > .body-col{
                            > .list-appointment-cash{
                                > .item {
                                    background: #0c0c0c;
                                    display: flex;
                                    flex-wrap: nowrap;
                                    justify-content: space-between;
                                    padding: 5px 20px;
                                    align-items: center;
                                    margin-bottom: 15px;
                                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                                    border-radius: 5px;

                                    .info-client , .info-crew {
                                        .info-top {
                                            display: flex;
                                            flex-wrap: nowrap;

                                            .thumb {
                                                padding-top: 5px;
                                                margin-right: 20px;
                                                cursor: pointer;
                                            }

                                            .info {
                                                p.name {
                                                    color: #fff;
                                                    font-weight: 500;
                                                    margin: 0;
                                                    padding-bottom: 5px;
                                                    font-size: 16px;
                                                    cursor: pointer;

                                                    span {
                                                        font-size: 14px;
                                                        background: #00a652;
                                                        display: inline-block;
                                                        padding: 5px 10px;
                                                        margin-left: 10px;
                                                        border-radius: 20px;
                                                    }
                                                }

                                                span.appointment-name {
                                                    font-size: 14px;
                                                    color: #808080;
                                                    font-weight: 500;
                                                }

                                                .address {
                                                    color: #fff;
                                                    font-size: 14px;
                                                    position: relative;
                                                    margin: 0;
                                                    padding: 10px 20px;

                                                    .anticon {
                                                        color: #feb034;
                                                        position: absolute;
                                                        top: 50%;
                                                        transform: translate(-50%, -50%);
                                                        left: 5px;
                                                        font-size: 11px;

                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .action-earning {
                                        min-width: 250px;
                                        text-align: center;

                                        p {
                                            text-align: center;
                                            color: #fff;
                                            font-weight: 500;
                                            margin: 5px;
                                        }

                                        button.detail {
                                            display: block;
                                            border-radius: 5px;
                                            border: none;
                                            color: #fff;
                                            text-align: center;
                                            font-weight: 500;
                                            margin: 10px auto;
                                            background: #6b48ff;
                                        }

                                        .duration{
                                            display: flex;
                                            justify-content: center;
                                            > p {
                                                margin-bottom: 0;

                                            }
                                            > p:first-child{
                                                margin-right: 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}