.modal-add-service {
    > .ant-modal-content{
        background: #0c0c0c;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 10px;
        .header-modal{
            text-align: right;
            button{
                color: #feb034;
                background: none;
                border: none;
                font-weight: 500;
            }
        }
        .body-modal{
            text-align: center;
            padding: 20px 70px 40px;
            #form-save-service{
                label{
                    color: #fff;
                    font-size: 16px;
                }
            }
            p{
                color: #fff;
                font-weight: 500;
            }
            button {
                background: #feb034;
                border: none;
                font-weight: 500;
                color: #000;
            }
            .anticon{
                font-size: 11px;
                color: #fff;
                padding: 0 10px 0 10px;
            }
            .ant-input-affix-wrapper{
                background: #1a1a1a;
                border: none;
                border-radius: 20px;
                margin-bottom: 10px;
            }
            input{
                height: 36px;
                background: #1a1a1a;
                color: #fff;
            }
            input::placeholder{
                color: #808080;
            }
            .ant-form-item-explain.ant-form-item-explain-error{
                div{
                    text-align: left;
                    margin-left: 10px;
                }
            }
            .select-service-of-crew{
                > .ant-select-selector{
                    background: #1a1a1a !important;
                    border: none;
                    border-radius: 20px;
                    height: 42px;
                    text-align: left;
                    margin-bottom: 10px;
                    > .ant-select-selection-placeholder{
                        line-height: 42px;
                        padding-left: 33px;
                        color: #808080;
                    }
                    > .ant-select-selection-item{
                        line-height: 42px;
                        padding-left: 33px;
                        color: #fff;
                    }
                }
                > .ant-select-arrow{
                    right: 20px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    > .anticon{
                        color: #808080;
                    }
                }

            }
        }
    }
}
