.connections-page {
  > .connection {
    .ant-tabs {
      .ant-tabs-nav::before {
        border-bottom: none;
      }

      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
      }

      .ant-tabs-nav {
        .ant-tabs-ink-bar-animated {
          max-width: 0;
        }


        .ant-tabs-nav-wrap {
          width: 100%;
          background: #0c0c0c;
          border-radius: 30px;

          .ant-tabs-nav-list {
            width: 100%;

            .ant-tabs-tab {
              width: 50%;
              text-align: center;
              border-radius: 30px;

              .ant-tabs-tab-btn {
                width: 100%;
                color: #808080;
                font-weight: 500;

              }
            }

            .ant-tabs-tab-active {
              background: #feb034;

              .ant-tabs-tab-btn {
                color: #000;
              }
            }
          }
        }
      }

      .ant-tabs-tabpane {
        background: #0c0c0c;
        min-height: 50vh;

        > .wp-tab-body {
          padding: 20px;

          .head-tab {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            > h5.title-body {
              color: #fff;
            }

            > button {
              background: none;
              border: none;
              color: #feb034;
              height: 0;
              padding: 0;
              margin: 0;

              > .anticon {
                font-size: 16px;
              }
            }
          }

          .form-search {
            position: relative;

            input {
              padding: 8px 10px;
            }

            input::placeholder {
              color: #808080;
            }

            .anticon.icon-search {
              position: absolute;
              top: 50%;
              right: 10px;
              color: #808080;
              font-size: 13px;
              transform: translate(-50%, -50%);
            }
          }

          .content-tab {

            > .list {
              margin-top: 30px;

              > .item {
                background: #0c0c0c;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding: 20px;
                align-items: center;
                margin-bottom: 15px;
                box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
                border-radius: 5px;

                > .left {
                  display: flex;

                  > .thumb {
                    margin-right: 15px;
                    //padding-top: 5px;
                    cursor: pointer;
                  }

                  > .name {
                    > p {
                      margin-bottom: 0;
                      color: #fff;
                      font-size: 16px;
                      cursor: pointer;
                    }

                    > p.address {
                      position: relative;
                      padding-left: 15px;
                      color: #808080;
                      font-size: 13px;

                      .anticon {
                        font-size: 10px;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 5px;
                        color: #feb034;
                      }
                    }
                  }
                }

                > .right {
                  > button.unfollow {
                    background: #4cd137;
                    color: #000;
                    font-weight: 500;
                    padding: 3px 10px;
                    border-radius: 5px;
                    cursor: pointer;
                    border: none;
                  }

                  > p.pending {
                    background: #feb034;
                    color: #000;
                    font-weight: 500;
                    padding: 3px 10px;
                    border-radius: 5px;
                    text-align: center;
                  }

                  > div {
                    > button {
                      background: none;
                      border: none;
                      font-weight: 500;
                      color: #000;
                    }

                    > button:hover {
                      color: #fff;
                    }

                    > button.confirm-connection {
                      background: #4cd137;
                      margin-right: 10px;
                    }

                    > button.reject-connection {
                      background: #e84118;
                    }
                  }
                }
              }

              @media (max-width: 400px) {
                > .item {
                  display: block;

                  > .right {
                    margin-top: 10px;
                    //text-align: center;
                    > button {
                      background: none;
                      border: none;
                      font-weight: 500;
                      color: #000;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.load-more-data {
  text-align: center;

  > button {
    background: none;
    border: none;
    color: #feb034;
    font-size: 13px;
  }

  > button:hover {
    background: none;
    border: none;
  }

  .spin-load-more-data {
    .ant-spin-dot {
      font-size: 14px;

      .ant-spin-dot-item {
        background-color: #fff;
        width: 6px;
        height: 6px;
      }
    }

    .ant-spin-text {
      font-size: 13px;
      color: #fff;
    }
  }
}

.widget-empty-data {
  background: #0c0c0c;
  min-height: 250px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 100px 0;
}

.wp-pagination {
  text-align: right;
  padding: 20px 0 30px;

  .pagination-custom {
    .ant-pagination-item-link {
      font-size: 11px !important;

      > .anticon {
        vertical-align: 1px !important;
      }
    }

    > li {
      font-size: 11px;
      font-weight: 500;
    }

    > li.ant-pagination-item-active {
      border-color: #feb034;
      background: #f3b034;
    }
  }
}
