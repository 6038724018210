.login-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 4rem;
    .thumb{
        width: 250px;
        height: 150px;
        img {
            width: 100%;
        }
    }
    > div{
        height: 85px;
        > .link-download-app{
            font-size: 14px;
            background: #f1c40f;
            padding: 8px 10px ;
            color: #000;
            border-radius: 5px;
            font-weight: 600;
            text-align: center;
        }
        > .link-download-app:hover{
            color: #0984e3;
        }
    }
}

@media screen and (max-width: 576px) {
    .login-logo{
        margin: 0 2rem;
    }
}

@media screen and (max-width: 450px) {
    .login-logo{
        margin: 0 0.5rem;
    }
}
@media screen and (max-width: 400px) {
    .login-logo{
        .thumb{
            width: 200px;
            height: 120px;
            img {
                width: 100%;
            }
        }
        > div{
            height: 68px;
            > .link-download-app{
                font-size: 14px;
                background: #f1c40f;
                padding: 8px 10px ;
                color: #000;
                border-radius: 5px;
                font-weight: 600;
                text-align: center;
            }
            > .link-download-app:hover{
                color: #0984e3;
            }
        }
    }
}

@media screen and (max-width: 335px) {
    .login-logo{
        display: block;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.login-form {
    display: flex;
    justify-content: space-around;
    padding: 4rem;

    input {
        padding-left: 10px !important;
        color: #fff;
    }

    .right {
        border-radius: 10px;
        width: 40%;
        background: #feaf34;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;

        .ant-input-affix-wrapper {
            line-height: 3;
        }

        input {
            background: #1a1a1a !important;
        }

        input::placeholder {
            color: #808080;
            font-weight: 500;
        }

        input:hover {
            background-color: #1a1a1a !important;
        }

        .ant-input-prefix {
            background: none;
        }

        .ant-input-affix-wrapper:hover {
            background-color: #1a1a1a !important;
        }

        span {
            border-radius: 30px;
            background: #1a1a1a;
            color: #ffffff;
            font-size: 15px;
            border: none;
        }

        p {
            font-weight: bold;
            color: #000000;
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        #btn-signup {
            border-radius: 30px;
            height: 50px;
            border: none;
            margin-bottom: 1rem;
            font-weight: 500;

            span {
                background: none;
                font-size: 20px;
                color: black;
            }
        }

        #normal_signup {
            width: 100%;
        }
    }

    .ant-checkbox + span {
        background: none;
    }

    .ant-checkbox-group-item {
        margin-right: 50px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #feaf34;
        border-color: #feaf34;
    }

    h2 {
        color: #feaf34;
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;

        a {
            text-decoration: underline;
            color: #7f7f7f;
            font-size: 17px;
        }

        #btn-login {
            background: #feaf34;
            border-radius: 30px;
            height: 50px;
            border: none;
            margin-bottom: 1rem;
            font-weight: 500;

            span {
                background: none;
                font-size: 20px;
                color: black;
            }
        }

        .ant-input-affix-wrapper {
            line-height: 3;
        }

        .ant-input-affix-wrapper:hover {
            background-color: #1a1a1a;
        }

        span {
            border-radius: 30px;
            background: #1a1a1a;
            color: #ffffff;
            font-size: 15px;
            border: none;
        }

        input {
            background: #1a1a1a;
        }

        input::placeholder {
            color: #484848;
            font-weight: 500;
        }

        input:hover {
            background-color: #1a1a1a;
        }

        .ant-input-password-icon{
            font-size: 12px;
            font-weight: 500;
        }
        .form-input {
            display: flex;
            flex-direction: column;
            width: 60%;
        }

        p {
            color: #ffffff;
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 2rem;
            color: #ffffff;
            margin-bottom: 1rem;
            font-weight: bold;
        }

        h5, h6 {
            color: #7f7f7f;
        }
    }
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
}

@media screen and (max-width: 670px) {
    .login-form{
        display: block;
        > .right{
            width: 100%;
        }
        > .left{
            width: 100%;
            margin-bottom: 30px;
            .form-input {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .login-form{
        padding: 2rem 4rem;
    }
}

