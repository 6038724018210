.wp-loading-data{
    text-align: center;
    background: #0c0c0c;
    .spin-loading-data{
        padding: 50px 0;
        .ant-spin-dot{
            font-size: 16px;
            .ant-spin-dot-item{
                background-color: #fff;
                width: 7px;
                height: 7px;
            }
        }
        .ant-spin-text{
            font-size: 13px;
            color: #fff;

        }
    }
}