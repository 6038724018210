.forgot-password-page {
    padding: 2rem;
    width: 50%;
    margin-left: 5rem;


    h3.title {
        color: #fff;
    }

    .ant-input-affix-wrapper {
        line-height: 3;
        border: none;
        border-radius: 30px;
        background: #1a1a1a;
        .ant-input-prefix{
            .anticon{
                color: #fff;
            }
        }
    }

    .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        background-color: #353333;
    }


    input {
        background: #1a1a1a;
        color: #fff;
        padding-left: 10px !important;
    }
    input::placeholder{
        color: #808080;
    }

    input:hover {
        background-color: #1a1a1a;
    }

    p {
        color: #7f7f7f;
        margin-bottom: 2rem;
        font-size: 1rem;
        font-weight: 500;
    }

    #btn-forgot, #btn_reset_password {
        background: #feaf34;
        border-radius: 30px;
        height: 42px;
        border: none;
        margin-bottom: 1rem;
        font-weight: 500;

        span {
            background: none;
            font-size: 20px;
            color: black;
        }
    }

    .header-form{
        display: flex;
        justify-content: space-between;
        button{
            background: none;
            border: none;
            color: #feb034;
            font-weight: 600;
            position: relative;
            padding-right: 25px;
            .anticon{
                position: absolute;
                font-size: 11px;
                top: 50%;
                transform: translate(-50%, -50%);
                right: 0;
            }
        }
        button[disabled], button[disabled]:hover, button[disabled]:focus, button[disabled]:active {
            color: #feb034;
            background: none;
            border: none;
            text-shadow: none;
            box-shadow: none;
        }
    }

    #reset-password-form{
        .wp-inner-reset-password{
            .ant-input-password-icon{
                color: #fff;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
}

.login-logo.thumb{
    width: 250px;
    height: 150px;

    > img{
        width: 100%;
    }
}
