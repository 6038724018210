.modal-create-credit-card{

    .DemoPickerWrapper {
        padding: 0 12px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        background: white;
        margin: 24px 0 48px;
        width: 100%;
    }

    .DemoPicker {
        font-size: 18px;
        border-radius: 3px;
        height: 48px;
        border: 0;
        width: 100%;
        color: #6772e5;
        outline: none;
        background: transparent;

        -webkit-appearance: none;
    }

    .DemoWrapper {
        margin: 0 auto;
        max-width: 500px;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .Demo {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 40%;
    }

    label {
        font-weight: 300;
        letter-spacing: 0.025em;
    }

    .ErrorMessage {
        color: #fff;
        position: absolute;
        display: flex;
        justify-content: center;
        padding: 0 15px;
        font-size: 13px;
        margin-top: 10px;
        width: 100%;
        transform: translateY(-15px);
        //opacity: 0;
        animation: fade 150ms ease-out;
        animation-delay: 50ms;
        animation-fill-mode: forwards;
        will-change: opacity, transform;
    }
    .ErrorMessage svg {
        margin-right: 10px;
    }
    //button {
    //    white-space: nowrap;
    //    border: 0;
    //    outline: 0;
    //    display: inline-block;
    //    height: 40px;
    //    line-height: 40px;
    //    padding: 0 14px;
    //    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    //    color: #fff;
    //    border-radius: 4px;
    //    font-size: 15px;
    //    font-weight: 600;
    //    text-transform: uppercase;
    //    letter-spacing: 0.025em;
    //    background-color: #6772e5;
    //    text-decoration: none;
    //    -webkit-transition: all 150ms ease;
    //    transition: all 150ms ease;
    //    margin-top: 10px;
    //}

    //button:hover {
    //    color: #fff;
    //    cursor: pointer;
    //    background-color: #7795f8;
    //    transform: translateY(-1px);
    //    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    //}

    input,
    .StripeElement {
        display: block;
        margin: 10px 0 20px 0;
        padding: 10px 14px;
        font-size: 1em;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border: 0;
        outline: 0;
        border-radius: 4px;
        color: white;
        font-weight: 500;
    }

    input::placeholder {
        color: #808080;
    }

    input:focus,
    .StripeElement--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
    }

    .StripeElement.IdealBankElement,
    .StripeElement.FpxBankElement,
    .StripeElement.PaymentRequestButton {
        padding: 0;
    }

    .StripeElement.PaymentRequestButton {
        height: 40px;
    }
    .ant-modal-body{
        background-color: #0c0c0c;
        padding: 20px;

        .head-modal {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            > button{
                color: #feb034;
                font-weight: 500;
                background: none;
                border: none;
                padding: 0;
            }
            p.title{
                font-weight: 500;
                font-size: 18px;
                color: #fff;
            }
        }
    }
    .payment-method {
        border-radius: 5px;
        margin-top: 30px;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;


        .ant-radio-group{
            display: flex;
            justify-content: center;
            .ant-radio-button-wrapper {
                background: none;
                height: auto;
                border: none;
                width: 250px;
                box-sizing: border-box;
                margin-right: 30px;
                padding: 0;
                position: relative;

                .card {
                    width: 100%;
                    height: 120px;
                    margin-right: 40px;
                    background: #1a1a1a;
                    border: none;
                    text-align: center;
                    position: relative;

                    > div {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .card-icon {
                            font-size: 35px;
                            color: #808080;
                        }

                        p {
                            color: #808080;
                            padding-top: 10px;
                        }
                    }
                    .anticon-check-circle{
                        position: absolute;
                        top: -3px;
                        right: -5px;
                        font-size: 13px;
                        color: #feb034;
                    }

                }
            }
            .ant-radio-button-wrapper:last-child{
                margin-right: 0;
            }
            .ant-radio-button-wrapper::before{
                background: none;
            }
            .ant-radio-button-wrapper-checked{
                border: 1px solid #feb034;
                .ant-radio-button-checked{
                    border: 1px solid #feb034;
                }
                > span > .card{
                    .card-icon, p {
                        color: #fff;
                    }
                }

            }
            .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
                border-right: 1px solid  #feb034 ;
            }
        }

        .details{
            > div{
                display: flex;
                width: 100%;
                padding: 0 20px;
                .ant-form-item:first-child , .ant-form-item.input-expired-year{
                    margin-right: 20px;
                }
                input{
                    background: #1a1a1a;
                    border: none;
                    padding: 8px 11px;
                    border-radius: 10px;
                    //margin-right: 20px;
                    color: #fff;
                }
                input::placeholder{
                    color: #808080;
                }
                .ant-form-item-label{
                    label{
                        color: #fff;
                        font-weight: 500;
                    }
                }
            }
        }

    }
    .wp-add-payment-method {

        .info-card{
            padding: 0 30px;
            label {
                display: block;
                color: #fff;
                width: 100%;
                font-weight: 500;
                .StripeElement{
                    max-width: 100%;
                    background: #1a1a1a;
                }
            }
            .info-more{
                display: flex;
                justify-content: space-between;
                > label:first-child{
                    margin-right: 20px;
                }
            }
        }
        .title {
            color: #fff;
            font-size: 18px;

            span {
                font-size: 14px;
                padding-left: 5px;
            }
        }

        #btn-add-payment-method {
            border-radius: 20px;
            background: #feb034;
            color: #000;
            font-weight: 500;
            border: none;
            outline: none;
            margin: 30px 0 100px 0;
        }


    }

}


