.modal-require-add-service {
    > .ant-modal-content{
        background: #0c0c0c;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 10px;
        .header-modal{
            text-align: right;
            button{
                color: #feb034;
                background: none;
                border: none;
                font-weight: 500;
            }
        }
        .body-modal{
            text-align: center;
            padding-bottom: 40px;
            p{
                color: #fff;
                font-weight: 500;
            }
            button {
                background: #feb034;
                border: none;
                font-weight: 500;
                color: #000;
            }
            .anticon{
                font-size: 40px;
                padding: 20px 0;
                color: #00a652;
            }
        }
    }
}