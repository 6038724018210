.schedule-page {
    height: 100%;

    > .calendar, .event {
        background: #0c0c0c;
        padding: 30px;
        margin-bottom: 30px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    > .calendar {
        .header-widget {
            text-align: right;
            margin-bottom: 20px;

            button {
                background: none;
                border: none;
                text-align: center;
                color: #feb034;
                position: relative;
                font-size: 16px;

                .anticon {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 10px;
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }

        .calendar-schedule {
            .custom-calendar {
                .ant-picker-calendar-header, .ant-picker-panel {
                    background: #0c0c0c;
                    border-top: none;
                }

                .ant-picker-calendar-header {
                    .ant-picker-calendar-mode-switch {
                        display: none;
                    }

                    .ant-picker-calendar-year-select, .ant-picker-calendar-month-select {
                        min-width: 150px;
                        text-align: center;
                    }
                    .ant-select-arrow{
                        color: #fff;
                        font-size: 9px;
                    }

                    .ant-select-selector {
                        background: #212121;
                        border: none;
                        padding: 8px 7px;
                        height: 36px;

                        span.ant-select-selection-item {
                            color: #fff;
                        }
                    }

                    .ant-select-dropdown {
                        background: #0c0c0c;

                        .ant-select-item-option {
                            color: #fff;
                        }

                        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
                            color: rgba(0, 0, 0, 0.85);
                            font-weight: 600;
                            background-color: #808080;
                        }

                        .ant-select-item-option:hover {
                            background-color: #808080;
                        }
                    }

                }

                .ant-picker-panel {
                    .ant-picker-date-panel{
                        .ant-picker-content{
                            thead{
                                height: 42px;
                                th{
                                    color: #fff;
                                    text-transform: uppercase;
                                    font-weight: 500;
                                }
                            }
                            tbody{
                                tr{
                                    height: 42px;
                                    .ant-picker-cell {
                                        padding: 3px 0;
                                        /* color: rgba(0, 0, 0, 0.25); */
                                        cursor: pointer;
                                        color:  #383838;;
                                        font-weight: 500;
                                        border: none;
                                    }
                                    .ant-picker-cell-in-view{
                                        color: #fff;
                                    }
                                    .ant-picker-calendar-date-today{
                                        background: #feb034;
                                        color: #000;
                                    }
                                    .ant-picker-calendar-date-today::before{
                                        border: none;
                                        outline: none;
                                    }
                                    .ant-picker-cell:hover .ant-picker-calendar-date-value , .ant-picker-calendar-date-value:hover{
                                        background: #808080;
                                    }
                                    .ant-picker-calendar-date{
                                        position: relative;
                                        .ant-picker-calendar-date-content{
                                            position: absolute;
                                            top: -10px;
                                            right: -12px;
                                            .ant-badge-status{
                                                font-size: 10px;
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }
    > .event{
        padding:  30px;
        h6.title{
            color: #fff;
            font-size: 18px;
            margin-bottom: 30px;
        }
        .ant-timeline{
            .ant-timeline-item{
                .ant-timeline-item-head{
                    color: #feb034;
                    //border-color: #feb034;
                    border: 2px solid #feb034;
                    background-color: #feb034;
                }
                .ant-timeline-item-content{
                    p.time{
                        color: #fff;
                        font-size: 12px;
                        margin-bottom: 5px;;
                    }
                    p.content{
                        color: #feb034;
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}