.crew-page {
  width: 80%;
  margin: 2rem auto;

  .ant-pagination {
    text-align: end;
  }

  #btn_book {
    background: #feb034;
    height: 36px;
    border: none;
    font-weight: 500;
    color: #000;
    max-width: 100px;
  }

  .anticon-dollar-circle {
    margin-left: 0;
  }

  .title-page {
    color: #feb034;
    font-size: 40px;
    text-transform: uppercase;
    font-style: italic;
    text-align: center;
    font-weight: bold;
  }

  .slogan {
    text-align: center;
    border-bottom: 1px solid rgba(175, 171, 171, 0.79);
    padding: 1rem 0;

    .slogan-title {
      font-size: 40px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      color: #ffffff;
      margin: 1rem 0;
    }

    .slogan-content {
      text-align: center;
      color: #808080;
      font-size: 20px;
      line-height: 25px;

      strong {
        color: #ffffff;
        font-weight: bold;
      }
    }
  }

  .search-wrapper {
    border-radius: 5px;
    border: 1px solid rgba(128, 128, 128, 0.47);
    padding: 1rem;

    .title {
      font-size: 30px;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      margin-bottom: 1rem;
    }

    .input-search {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .position, .location {
        width: 50%;

        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
          line-height: 48px;
          color: white;
        }

        .ant-select-selection-placeholder {
          color: #5e5e5e;
          line-height: 48px !important;
        }

        .ant-select-selector {
          height: 100%;
        }

        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
          padding: 0 11px;
          background: #1a1a1a !important;
          border-radius: 10px;
          border: none;
        }

        .ant-select-arrow {
          color: #ffffff;
        }

        .ant-input-affix-wrapper {
          background: #1a1a1a;
          border-color: #1a1a1a;
          border-radius: 10px;

          input::placeholder {
            color: #5e5e5e;
          }

          .ant-input {
            height: 40px;
            outline: none !important;
            box-shadow: none !important;
            font-size: 16px;

            &::placeholder {
              font-size: 14px;
            }
          }
        }
      }
    }
  }


  .list-crews {
    .empty-data {
      .ant-empty-normal {
        margin: 10px;

        .ant-empty-image {
          .ant-empty-img-simple {
            width: 30px;
            height: 25px;
          }
        }

        .ant-empty-description {
          color: #feb034;
        }
      }
    }

    .show-loading {
      text-align: center;
      min-height: 100px;

      > .ant-spin {
        line-height: 100px;
      }
    }

    .list {
      margin-top: 2rem;

      .wp-info-crew {
        display: block;
        padding: 10px 0;
        cursor: pointer;

        .info-crew {
          border: 1px solid #808080;
          display: flex;
          justify-content: space-between;
          padding: 10px;
          border-radius: 5px;
          background-color: #0c0c0c;

          .info {
            display: flex;
            width: 100%;


            .thumb {
              width: 80px;
              text-align: center;
              margin-right: 20px;
              padding-top: 10px;

              .ant-avatar{
                width: 64px;
                height: 64px;
              }

              p {
                color: #fff;
                margin-top: 10px;
                margin-bottom: 0;
                font-weight: 500;
              }

              span {
                color: #fff;
              }

              img {
                display: inline-block;
              }
            }

            .detail {
              width:  calc(100% - 80px);

              p {
                color: #808080;
              }

              p.name {
                font-size: 18px;
                font-weight: 500;
                color: #fff;
              }

              p:not(p.name) {
                padding-left: 10px;
                position: relative;

                > .anticon {
                  color: #feb034;
                  font-size: 14px;
                  position: absolute;
                  top: 45%;
                  left: 0;
                  transform: translate(-50%, -50%);
                }
              }

              .name-rate {
                display: flex;
                justify-content: space-between;

                .rate {
                  .ant-rate {
                    font-size: 0;

                    .ant-rate-star {
                      font-size: 13px;
                    }
                  }

                }
              }
            }
          }

          .portfolios{
            margin-top: 1rem;
            margin-bottom: 1rem;
            p.title{
              padding-left: 0 !important;
              color: #fff;
              font-size: 14px;
              text-transform: uppercase;
              margin-bottom: 5px;
            }
            .slick-slide{
              padding-right: 5px;
            }
            .list{
              display: flex;
              margin-top: 0;
             .ant-image{
               width: calc(100% / 3);
               padding-left: 5px;
             }
            }
          }
        }

        .info-crew.selected {
          border: 1px solid #feb034;
          position: relative;

          > .anticon {
            position: absolute;
            top: -5px;
            right: -5px;
            color: #feb034;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .crew-page {
    .title-page {
      font-size: 30px;
    }

    .slogan {
      .slogan-title {
        font-size: 30px;
      }

      .slogan-content {
        font-size: 15px;
      }
    }

    .search-wrapper {
      .title {
        font-size: 20px;
      }


      .input-search {
        flex-direction: column;
        gap: 10px;

        .date-time {
          width: 100%;
        }

        .position, .location {
          width: 100%;
        }
      }
    }
  }
}
