@mixin info {
  border: 1px solid rgba(128, 128, 128, 0.47);
  padding: 0 2rem;
  border-radius: 5px;
  background-color: #0c0c0c;
  width: 100%;
}

.about-us-page {
  width: 100%;
  padding: 2rem;
  margin-bottom: 2rem;

  .information {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .slogan {
      .slogan-content {
        color: #ffffff;
        font-size: 18px;
        line-height: 25px;

        strong {
          color: #ffffff;
          font-weight: bold;
        }
      }
    }

    .sub-title {
      font-size: 20px;
      font-weight: 600;
      color: #feb034;
      text-transform: uppercase;
    }

    .sub-question {
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      color: #ffffff;
    }

    .top-info {
      @include info;

      .info-top {
        width: 100%;
        padding: 1rem 0;

        p {
          color: #ffffff;
        }

        p.name {
          font-size: 18px;
          font-weight: 200;
          color: #fff;
        }

        p:not(p.name) {
          padding-left: 25px;
          position: relative;

          > .anticon {
            color: #feb034;
            font-size: 15px;
            position: absolute;
            top: 45%;
            padding-right: 2rem;
            transform: translate(-50%, -50%);
          }
        }
      }

      .info-bottom {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        border-top: 1px solid rgba(128, 128, 128, 0.47);

        .ant-space-horizontal {
          &:first-child {
            border-right: 1px solid rgba(128, 128, 128, 0.47);
          }

          width: 50%;
        }

        .slogan-content {
          font-size: 16px;
          font-weight: 200;
          color: #ffffff;
        }
      }

      .info-faq {
        padding: 2rem 0;

        .slogan {
          padding: 1rem;
          background: rgb(37, 37, 37);
          background: linear-gradient(90deg, rgba(37, 37, 37, 1) 25%, rgba(0, 0, 0, 1) 100%, rgba(37, 37, 37, 1) 100%);
        }

        .slogan-content {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .about-us-page {

    .information {
      flex-direction: column;
      gap: 10px;

      .info-bottom {
        padding: 2rem 0;
        flex-direction: column;

        .ant-space-horizontal {
          width: 100% !important;

          img {
            width: 50px;
          }

          &:first-child {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(128, 128, 128, 0.47);
            border-right: unset !important;
          }

        }
      }

      .top-info {
        img {
          max-width: 250px;
        }
      }
    }
  }
}
