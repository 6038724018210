.wp-notification {
    background: #fff;
    width: 375px;
    border-radius: 5px;
    padding: 10px;

    .header-notification{
        display: flex;
        justify-content: space-between;
        .action{
            padding-right: 20px;
            .anticon{
                font-weight: 600;
                font-size: 20px;
                padding: 5px 10px;
            }
            .anticon:hover{
                background: #808080;
                border-radius: 50%;
                color: #fff;
            }
        }
    }

    .notification {
        max-height: 350px;
        overflow: auto;

        .ant-list-item{
            padding: 12px 10px;
            .action{
                display: none;
            }
        }
        .ant-list-item:hover{
            .action{
                display: block;
            }
        }

        .ant-list-item-meta-title {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            cursor: pointer;
        }
        .ant-list-item-meta-description{
            font-size: 13px;
            color: #808080;
        }
    }

    > .footer{
        text-align: center;
        padding: 20px 0 10px 0;
        > button {
            background: none;
            border: none;
            outline: none;
            font-weight: 500;
            color: #0984e3;
        }
    }


}
.ant-dropdown.dropdown-action-notification-header{
    .ant-dropdown-menu{
        background: #fff;
        .ant-dropdown-menu-item{
            a{
                color: #000;
                //line-height: 24px;
                padding: 5px 20px;
                position: relative;
                .anticon{
                    position: relative;
                    top: 50%;
                    font-size: 9px;
                    left: 0;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .ant-dropdown-menu-item:hover{
            background: #808080;
            a{
                text-decoration: none;
                color: #fff;
            }
        }
    }

}