.modal-show-profile{
    > .ant-modal-content{
        background: #0c0c0c;
        > .ant-modal-body{
            background: #0c0c0c;
            height: 90vh;
            overflow: auto;
            padding: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            > .head-modal{
                display: flex;
                justify-content: space-between;
                text-align: right;
                margin-bottom: 15px;
                > button {
                    background: none;
                    border: none;
                    color: #feb034;
                    font-size: 14px;
                    font-weight: 500;
                }
                h4.title{
                    color: #fff;
                    padding-left: 20px;
                    padding-top: 10px;
                }
            }
            .result-detail-page {
                padding: 20px;
                .header-profile {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    .profile {
                        display: flex;
                        flex-wrap: nowrap;

                        .avatar-profile {
                            padding-top: 10px;
                            margin-right: 30px;
                        }

                        .info {
                            .name {
                                color: #fff;
                                padding: 10px 0 5px;
                                margin: 0;
                            }

                            .username {
                                color: #808080;
                            }

                            div.rate {
                                padding: 5px 0;
                                color: #feb034;

                                .ant-rate {
                                    font-size: 13px;
                                }
                            }

                            .address {
                                position: relative;
                                padding-left: 15px;
                                color: #fff;

                                > .icon-address {
                                    font-size: 11px;
                                    position: absolute;
                                    left: 5px;
                                    top: 45%;
                                    transform: translate(-50%, -50%);
                                    color: #feb034;
                                }
                            }
                        }
                    }

                    .info-work {
                        .statistical {
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;

                            div {
                                margin-right: 30px;

                                p {
                                    color: #fff;
                                    text-transform: uppercase;
                                    font-size: 26px;
                                    text-align: center;
                                    font-weight: 500;
                                }
                            }

                            div:last-child {
                                margin-right: 0;
                            }
                        }

                        @media (max-width: 576px) {
                            .statistical{
                                div{
                                    p{
                                        font-size: 20px;
                                    }
                                }

                            }
                        }
                        @media (max-width: 476px) {
                            .statistical{
                                div{
                                    margin-right: 15px;
                                    p{
                                        font-size: 18px;
                                    }
                                }
                                div:last-child {
                                    margin-right: 0;
                                }
                            }
                        }

                        @media (max-width: 400px) {
                            .statistical{
                                div{
                                    p{
                                        font-size: 16px;
                                    }
                                }
                            }
                        }

                        .action {
                            text-align: center;

                            button {
                                background-color: transparent;
                                border: 1px solid #feb034;
                                width: 200px;
                                margin-right: 10px;
                                color: #feb034;
                                border-radius: 20px;
                                display: inline-block;
                                font-weight: 500;
                            }

                            button:hover {
                                background: #feb034;
                                color: #000;
                                transition: color 0.5s;
                            }

                            a:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .body-profile {
                    padding-bottom: 50px;

                    > div {
                        min-height: 200px;
                        padding: 30px 50px;
                        background: #0c0c0c;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                        border: 1px solid #333333;
                        margin-bottom: 30px;
                    }

                    h6.title {
                        position: relative;
                        padding-left: 20px;
                        color: #fff;

                        > .icon-info {
                            font-size: 12px;
                            position: absolute;
                            left: 5px;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: #feb034;
                        }
                    }


                    .about-me {
                        p {
                            color: #808080;
                            line-height: 28px;
                            font-size: 14px;
                        }
                    }
                    @media (max-width: 576px) {
                        .about-me{
                            padding : 30px 10px;
                            div{
                                padding-left: 10px;
                            }
                        }
                    }

                    .portfolio {
                        > .header-widget{
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: nowrap;
                            margin-bottom: 10px;
                            > button{
                                background: none;
                                color: #feb034;
                                padding: 0 3px;
                                height: 24px;
                                border: none;
                                font-size: 20px;
                                line-height: 0;
                            }
                        }
                        margin-bottom: 30px;
                        > .widget{
                            > .title{
                                margin-bottom: 0;
                                padding-left: 10px;
                                color: #808080;
                            }
                            > .list {
                                display: flex;
                                padding: 10px 15px;
                                flex-wrap: wrap;

                                > .item {
                                    //padding: 10px;
                                    font-size: 30px;
                                    text-align: center;
                                    width: 24%;
                                    height: 150px;
                                    overflow: hidden;
                                    margin-bottom: 20px;
                                    margin-right: 1.33%;
                                    position: relative;
                                    > .ant-image{
                                        width: 100%;
                                        height: 100%;
                                        overflow: hidden;
                                        img{
                                            max-width: 100%;
                                            max-height: 100%;
                                            object-fit : cover;
                                        }
                                        .ant-image-mask{
                                            .ant-image-mask-info{
                                                font-size: 11px;
                                                position: relative;
                                                padding-left: 15px;
                                                > .anticon{
                                                    position: absolute;
                                                    left: 0;
                                                    font-size: 9px;
                                                }
                                            }
                                        }
                                    }
                                    > img{
                                        max-width: 100%;
                                        max-height: 100%;
                                        object-fit : cover;
                                        cursor: pointer;
                                    }
                                    > video{
                                        max-width: 100%;
                                        max-height: 100%;
                                        object-fit: cover;
                                        cursor: pointer;
                                        position: relative;
                                    }
                                    .anticon.open-model{
                                        position: absolute;
                                        top: 40%;
                                        transform: translate(-50% , -50%);
                                        left: 50%;
                                        color: #fff;
                                        font-size: 13px;
                                        opacity: 1;
                                    }
                                    video:hover, img:hover{
                                        opacity: 0.5;
                                    }
                                    .btn-delete-portfolio{
                                        position: absolute;
                                        top: 0;
                                        right: 5px;
                                        color: #fff;
                                        font-size: 13px;
                                        background: none;
                                        border: none;
                                    }
                                }
                                > .item:nth-child(4n){
                                    margin-right: 0;
                                }
                            }
                            .list-image{
                                > .item {
                                    font-size: 30px;
                                    text-align: center;
                                    height: 200px;
                                    overflow: hidden;
                                    position: relative;
                                }
                            }
                        }
                    }

                    @media (max-width: 576px) {
                        .portfolio{
                            padding : 30px 10px;
                            div{
                                padding-left: 10px;
                            }
                        }
                    }

                    .services-profile {
                        > .list {
                            padding: 10px;
                            > .item {
                                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                                text-align: left;
                                background: #000;
                                padding: 10px 20px;
                                border-radius: 5px;
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 10px;
                                .name{
                                    color: #fff;
                                    font-size: 16px;
                                    font-weight: 500;
                                }
                                .price{
                                    > div{
                                        display: flex;
                                        justify-content: space-between;
                                        > p{
                                            color: #fff;
                                            font-size: 14px;
                                        }
                                        > p:first-child{
                                            padding-right: 30px;
                                            color: #808080;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @media (max-width: 576px) {
                        .services-profile{
                            padding : 30px 10px;
                            div{
                                padding-left: 10px;
                            }
                        }
                    }

                    .contact-info {
                        div {
                            padding-left: 2rem;
                            p {
                                position: relative;
                                padding-left: 20px;
                                color: #fff;

                                > .icon-contact {
                                    font-size: 10px;
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    color: #feb034;
                                }
                            }
                        }

                    }

                    @media (max-width: 576px) {
                    .contact-info{
                        padding : 30px 10px;
                        div{
                            padding-left: 10px;
                        }
                    }
                }
                }

                @media screen and (max-width: 900px) {
                    .header-profile{
                        display: block;
                        > .info-work{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}
/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.modal-book-crew-now{
    > .ant-modal-content{
        background: #0c0c0c;
        > .ant-modal-body{
            background: #0c0c0c;
            overflow: auto;
            padding: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 10px;
            > .head-modal{
                display: flex;
                justify-content: space-between;
                text-align: right;
                margin-bottom: 15px;
                padding: 20px 10px;
                > button {
                    background: none;
                    border: none;
                    color: #feb034;
                    font-size: 14px;
                    font-weight: 500;
                    height: 0;
                }
                h4.title{
                    color: #fff;
                    margin: 0;
                    padding: 0;
                }
            }
            > .body-modal{
                padding: 20px;
                form {
                    label{
                        color: #fff;
                        font-weight: 500;
                    }
                    .ant-select-selector{
                        background: #1a1a1a;
                        border: none;
                        color: #fff;
                    }
                    .btn-book-now{
                        color: #000;
                        border: none;
                        background: #feb034;
                        font-weight: 500;
                    }
                    .ant-select-selection-placeholder{
                        color: #808080;
                    }
                }
            }
        }
    }
}